import { MutationHookOptions, useMutation, useQuery } from '@apollo/client'
import {
    DATA_TYPE_CREATE,
    DATA_TYPE_DELETE,
    DATA_TYPE_REMOVE_ATTRIBUTE,
    DATA_TYPE_REORDER_ATTRIBUTES,
    DATA_TYPE_UPDATE,
} from 'graphql/mutations/dataType'
import { DATA_TYPE_GET } from 'graphql/queries/dataTypeGet'
import {
    AttributeCommon,
    AttributeLinkAllowedOptions,
    DataType,
    DataTypeAttributeType,
    DataTypeCategory,
    Mutation,
    MutationDataTypeCreateArgs,
    MutationDataTypeDeleteArgs,
    MutationDataTypeRemoveAttributeArgs,
    MutationDataTypeReorderAttributesArgs,
    MutationDataTypeUpdateArgs,
    Query,
    QueryDataTypeGetArgs,
} from 'graphql/types'
import { useSettingsErrors } from 'pages/SettingsPage/useSettingsErrors'
import { SetStateAction, useEffect, useState } from 'react'
import { DropResult, OnDragEndResponder } from 'react-beautiful-dnd'
import { useStoreState } from 'store/hooks'
import {
    getAttributeTypeFromTypename,
    getOrderedAttributeUnion,
    useDataTypeCreateAttributeMutation,
    useDataTypeUpdateAttributeMutation,
} from 'utils/dataType/functions'
import {
    AttributeInputUnion,
    AttributeUnion,
    createDefaultDataType,
    getDataTypeInputFromType,
} from 'utils/dataType/types'
import { FormError, UnpublishedItem } from 'utils/types'
import { v4 } from 'uuid'
import { DataTypeTabProps } from './DataTypeTab'

export type UseDataTypeTabsReturn = DataTypeTabProps & {
    // data
    dataTypes: DataType[]
    subTypes: DataType[] // for attributeCollection
    unpublishedItems: UnpublishedItem[]
    attributes: AttributeUnion[]

    // selection
    selectedType: DataType | undefined
    setSelectedType: (item: DataType) => void
    selectedAttribute: AttributeUnion | undefined
    setSelectedAttribute: (item: AttributeUnion) => void

    // actions
    onClickType: (item: DataType) => void
    onClickCreateType: () => void
    onClickSaveType: () => void
    onClickDeleteType: () => void

    onClickCancelAttribute: () => void
    onClickSaveAttribute: () => void
    onClickCreateAttribute: (attributeType: DataTypeAttributeType) => void
    onClickAttribute: (item: AttributeUnion) => void
    onClickDeleteAttribute: (item: AttributeUnion) => void
    onClickToggleVisibilityAttribute: (item: AttributeUnion) => void
    onDragEndAttribute: OnDragEndResponder

    // error handling
    errors: FormError[]
}

const mutationOptions: Omit<MutationHookOptions, 'variables'> = {
    refetchQueries: [DATA_TYPE_GET],
}

// propagate TabProps through the entire stack
export const useDataTypesTab = (props: DataTypeTabProps): UseDataTypeTabsReturn => {
    const { category } = props

    // global state
    const website = useStoreState((state) => state.model.selectedWebsite)?.id ?? ''
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)

    // local state
    const [selectedType, setSelectedType] = useState<DataType>()
    const [attributes, setAttributes] = useState<AttributeUnion[]>([])
    const [selectedAttribute, setSelectedAttribute] = useState<AttributeUnion>()

    // queries

    // this is unused if category is subType, but not too big of a waste since its just a few bytes
    const { data: dataElementDefinitionGet } = useQuery<Query, QueryDataTypeGetArgs>(DATA_TYPE_GET, {
        variables: {
            category: DataTypeCategory.ELEMENT_DEFINITION,
            website,
        },
    })
    const elementDefinitions = dataElementDefinitionGet?.dataTypeGet ?? []

    const { data: dataDataModuleGet } = useQuery<Query, QueryDataTypeGetArgs>(DATA_TYPE_GET, {
        variables: {
            category: DataTypeCategory.DATA_MODULE,
            website,
        },
    })
    const dataModules = dataDataModuleGet?.dataTypeGet ?? []

    const { data: dataSubTypesGet } = useQuery<Query, QueryDataTypeGetArgs>(DATA_TYPE_GET, {
        variables: {
            category: DataTypeCategory.SUB_TYPE,
            website,
        },
    })
    const subTypes = dataSubTypesGet?.dataTypeGet ?? []

    let dataTypes: DataType[]
    if (category === DataTypeCategory.ELEMENT_DEFINITION) dataTypes = elementDefinitions
    else if (category === DataTypeCategory.DATA_MODULE) dataTypes = dataModules
    else dataTypes = subTypes

    // error handling
    const { errors, setErrors } = useSettingsErrors(selectedType, dataTypes, selectedType, selectedAttribute)

    // useEffects
    // default select first
    useEffect(() => {
        if (!selectedType) setSelectedType(dataTypes.at(0))
        else setSelectedType(dataTypes.find((item) => item.id === selectedType.id))
    }, [JSON.stringify(dataTypes)])

    // reset state on category switch
    useEffect(() => {
        setSelectedType(dataTypes.at(0))
        setSelectedAttribute(undefined)
    }, [category])

    // build attributes from linked list
    useEffect(() => {
        if (selectedType) setAttributes(getOrderedAttributeUnion(selectedType))
        setSelectedAttribute(undefined)
    }, [selectedType])

    // mutations
    const [createDataType] = useMutation<Mutation, MutationDataTypeCreateArgs>(DATA_TYPE_CREATE, {
        ...mutationOptions,
        onCompleted: (data) => selectedType && setSelectedType({ ...selectedType, id: data.dataTypeCreate }),
    })
    const [updateDataType] = useMutation<Mutation, MutationDataTypeUpdateArgs>(DATA_TYPE_UPDATE, mutationOptions)
    const [deleteDataType] = useMutation<Mutation, MutationDataTypeDeleteArgs>(DATA_TYPE_DELETE, {
        ...mutationOptions,
        onCompleted: () => setSelectedType(undefined),
    })

    const [createDataTypeAtttribute] = useDataTypeCreateAttributeMutation(mutationOptions)
    const [updateDataTypeAtttribute] = useDataTypeUpdateAttributeMutation(mutationOptions)
    const [removeDataTypeAttribute] = useMutation<Mutation, MutationDataTypeRemoveAttributeArgs>(
        DATA_TYPE_REMOVE_ATTRIBUTE,
        mutationOptions,
    )
    const [reorderDataTypeAttributes] = useMutation<Mutation, MutationDataTypeReorderAttributesArgs>(
        DATA_TYPE_REORDER_ATTRIBUTES,
        mutationOptions,
    )

    // functions
    // TODO: handle draft confirm discard
    const onClickCreateType = () => setSelectedType(createDefaultDataType(category))
    const onClickType = (item: DataType) => {
        setErrors([])
        setSelectedType(item)
    }

    const onClickSaveType = () => {
        if (selectedType) {
            const input = getDataTypeInputFromType(selectedType)

            if (selectedType.id === '') {
                input.id = v4()
                createDataType({
                    variables: { input, website },
                })
            } else updateDataType({ variables: { input } })
        }
    }
    const onClickDeleteType = () =>
        selectedType &&
        deleteDataType({
            variables: {
                id: selectedType?.id,
            },
        })

    const onClickCancelAttribute = () => {
        setErrors(errors.filter((e) => !e.type.startsWith('inner')))
        setSelectedAttribute(undefined)
    }
    const onClickSaveAttribute = () => {
        if (selectedType && selectedAttribute) {
            if (selectedAttribute.id === '') {
                const id = v4()

                const input = {
                    ...selectedAttribute,
                    id,
                    _type: getAttributeTypeFromTypename(selectedAttribute.__typename ?? 'AttributeText'),
                }
                createDataTypeAtttribute(selectedType.id, input as AttributeInputUnion)
            } else {
                const input = {
                    ...selectedAttribute,
                    _type: getAttributeTypeFromTypename(selectedAttribute.__typename ?? 'AttributeText'),
                }
                updateDataTypeAtttribute(input as AttributeInputUnion)
            }
        }
        setSelectedAttribute(undefined)
    }

    const onClickCreateAttribute = (attributeType: DataTypeAttributeType) => {
        const id = ''
        const common: AttributeCommon = { identifier: '', description: '', name: '', visible: true }

        let attribute: AttributeUnion
        switch (attributeType) {
            case DataTypeAttributeType.ASSET:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeAsset',
                }
                break
            case DataTypeAttributeType.BOOLEAN:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeBoolean',
                }
                break
            case DataTypeAttributeType.COLLECTION:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeCollection',
                    dataType: '',
                }
                break
            case DataTypeAttributeType.LINK:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeLink',
                    allowed: AttributeLinkAllowedOptions.INTERNAL_EXTERNAL_FILE,
                }
                break
            case DataTypeAttributeType.NUMBER:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeNumber',
                }
                break
            case DataTypeAttributeType.SELECTION:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeSelection',
                    options: [],
                }
                break
            case DataTypeAttributeType.TEXT:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeText',
                }
                break
            case DataTypeAttributeType.DATE:
                attribute = {
                    id,
                    common,
                    __typename: 'AttributeDate',
                }
                break
        }

        setSelectedAttribute(attribute)
    }

    const onClickAttribute = (value: SetStateAction<AttributeUnion | undefined>) => {
        setErrors(errors.filter((e) => !e.type.startsWith('inner')))
        setSelectedAttribute(value)
    }
    const onClickDeleteAttribute = (item: AttributeUnion) => removeDataTypeAttribute({ variables: { id: item.id } })
    const onClickToggleVisibilityAttribute = (item: AttributeUnion) => {
        const input = {
            ...item,
            common: {
                ...item.common,
                visible: !item.common.visible,
            },
            _type: getAttributeTypeFromTypename(item.__typename ?? 'AttributeText'),
        }
        updateDataTypeAtttribute(input as AttributeInputUnion)
    }

    const onDragEndAttribute = (result: DropResult) => {
        const { destination, source } = result
        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return
        if (destination.droppableId !== source.droppableId) return

        const list = Array.from(attributes)
        const [removed] = list.splice(source.index, 1)
        list.splice(destination.index, 0, removed)

        setAttributes(list)
        reorderDataTypeAttributes({ variables: { order: list.map((item) => item.id) } })
    }

    return {
        dataTypes,
        subTypes,
        onClickCreateType,
        onClickType,
        unpublishedItems,
        selectedType,
        setSelectedType,
        onClickDeleteType,
        onClickSaveType,
        selectedAttribute,
        setSelectedAttribute,
        onClickCreateAttribute,
        attributes,
        onClickCancelAttribute,
        onClickSaveAttribute,
        onDragEndAttribute,
        onClickAttribute,
        onClickDeleteAttribute,
        onClickToggleVisibilityAttribute,
        errors,
        ...props,
    }
}
