import { Delete, Edit, FileCopy, History, Search, Settings, SettingsBackupRestore } from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { Block, BlockVersion } from 'graphql/types'
import { TFunction } from 'i18next'
import { cloneDeep } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface BlockDetailsPanelrops {
    onEditBlock: (block: Block) => void
    onEditSettings: (blockID: string) => void
    onDuplicate: (blockID: string) => void
    onDelete: (blockID: string) => void
    showBlockVersion: (version: BlockVersion) => void
    reactivateBlockVersion: (version: BlockVersion) => void
    block: Block
}

const timeFormat = 'dd.MM.yyyy HH:mm'

const getLastPublished = (block: Block): string => {
    const blockHistoryVersions = cloneDeep(block.historyVersions)
    for (const blockVersion of blockHistoryVersions.sort((a, b) => b.updatedAt - a.updatedAt)) {
        //TODO: move state in blockversion
        if (blockVersion.state === 'PUBLISHED') {
            return format(new Date(blockVersion.updatedAt), timeFormat)
        }
    }
    return '-'
}

const renderVersionEntry = (
    version: BlockVersion,
    isCurrentVersion: boolean,
    showBlockVersion: (version: BlockVersion) => void,
    reactivateBlockVersion: (version: BlockVersion) => void,
    t: TFunction<'translation', undefined>,
) => {
    return (
        <>
            <Box display="flex" flexDirection="row" flex={1}>
                <Grid container flexDirection={'row'} alignItems={'center'}>
                    <Grid xs={10} item>
                        <Typography>{format(new Date(version.updatedAt), timeFormat)}</Typography>
                        <Typography>{version.updatedBy.name}</Typography>
                    </Grid>
                    <Grid xs={2} item flexDirection="column">
                        <Tooltip title={t('drawerPanels.blockDetailsPanel.show')} placement="right">
                            <Button
                                variant="text"
                                onClick={() => {
                                    showBlockVersion(version)
                                }}
                            >
                                <Search />
                            </Button>
                        </Tooltip>
                        {!isCurrentVersion ? (
                            <Tooltip title={t('drawerPanels.blockDetailsPanel.reactivate')} placement="right">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        reactivateBlockVersion(version)
                                    }}
                                >
                                    <SettingsBackupRestore />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button style={{ opacity: 0 }} disabled>
                                <SettingsBackupRestore />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </>
    )
}

const BlockDetailsPanel = (props: BlockDetailsPanelrops): JSX.Element => {
    const { onEditBlock, onEditSettings, onDuplicate, onDelete, block, reactivateBlockVersion, showBlockVersion } =
        props

    const { t } = useTranslation()

    const lastPublished = getLastPublished(block)

    const [displayBlockVersion, setDisplayBlockVersion] = useState<boolean>(false)

    useEffect(() => {
        setDisplayBlockVersion(false)
    }, [block])

    return (
        <Grid container direction="column" p={2} spacing={1}>
            <Grid container item direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                    <Typography variant="h6">{block.currentVersion.name}</Typography>
                </Grid>
                <Grid item xs={2} justifyItems={'flex-end'}>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.edit')} placement="right">
                        <IconButton
                            onClick={() => {
                                onEditBlock(block)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.lastChange')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.lastChange')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    {format(new Date(block.currentVersion.updatedAt), timeFormat)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.changedBy')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.changedBy')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={block.currentVersion.updatedBy.name} placement="left">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '130px',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            {block.currentVersion.updatedBy.name}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.lastPublish')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.lastPublish')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{lastPublished}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.status')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.status')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{t(`common.${block.currentVersion.state}`)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={10} display="flex" alignContent="end">
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.settings')}>
                        <IconButton
                            onClick={() => {
                                onEditSettings(block.id)
                            }}
                        >
                            <Settings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.duplicate')}>
                        <IconButton
                            onClick={() => {
                                onDuplicate(block.id)
                            }}
                        >
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.delete')}>
                        <IconButton
                            onClick={() => {
                                onDelete(block.id)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.versionHistory')}>
                        <IconButton
                            onClick={() => {
                                setDisplayBlockVersion(!displayBlockVersion)
                            }}
                        >
                            <History />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {displayBlockVersion && (
                <Grid container item direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('drawerPanels.blockDetailsPanel.versions')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {renderVersionEntry(block.currentVersion, true, showBlockVersion, reactivateBlockVersion, t)}
                    </Grid>
                    {block.historyVersions.slice(1).map((blockVersion: BlockVersion, index: number) => {
                        return (
                            <Fragment key={blockVersion.id}>
                                {index > 0 && <Divider />}
                                {renderVersionEntry(blockVersion, false, showBlockVersion, reactivateBlockVersion, t)}
                            </Fragment>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}

export default BlockDetailsPanel
