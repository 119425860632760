import { Add, Clear } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useDataItemEditPanel } from 'drawerPanels/DataItemEditPanel/useDataItemEditPanel'
import { DataType } from 'graphql/types'
import { UseDataItemPageReturn } from 'pages/DataItemPage/UseDataItemPage'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

export type DataItemEditPanelProps = Omit<UseDataItemPageReturn, 'selectedType'> & { selectedType: DataType }

const DataItemEditPanel = (props: DataItemEditPanelProps): JSX.Element | null => {
    const { items, onClickCreateItem, onClickPublishAll, setSelectedItem, selectedItem } = props

    const { t } = useTranslation()

    const { filteredItems, searchTerm, setSearchTerm, tableHeaders, apiRef } = useDataItemEditPanel(props)

    return (
        <Box>
            <Box display={'flex'} justifyContent={'space-between'}>
                <TextField
                    id={'search'}
                    variant="outlined"
                    size={'small'}
                    label={t('drawerPanels.dataItemEditPanel.search')}
                    autoFocus
                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                    value={searchTerm}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                disabled={searchTerm === ''}
                                sx={{ opacity: searchTerm === '' ? 0 : 1, marginRight: '-8px', marginLeft: '4px' }}
                                onClick={() => setSearchTerm('')}
                            >
                                <Clear />
                            </IconButton>
                        ),
                    }}
                />
                <Stack direction="row" alignItems="center" spacing={2} mb={2}>
                    <Tooltip title={t('drawerPanels.dataItemEditPanel.add')} placement="bottom">
                        <Button variant="contained" onClick={onClickCreateItem}>
                            <Add />
                        </Button>
                    </Tooltip>
                    <Button variant="contained" onClick={onClickPublishAll}>
                        {t('drawerPanels.dataItemEditPanel.publishAll')}
                    </Button>
                </Stack>
            </Box>
            <DataGrid
                apiRef={apiRef}
                columns={tableHeaders}
                rows={filteredItems}
                autoHeight
                rowHeight={72}
                disableColumnMenu
                sx={{
                    '& .MuiDataGrid-columnSeparator': {
                        opacity: '0 !important',
                    },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        bgcolor: colors.whiteHalfOpacity,
                    },
                    '& .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(1)': {
                        paddingBottom: '4px',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontFamily: 'Roboto',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        fontSize: '1.25rem',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: colors.white,
                    },
                    '& .MuiDataGrid-cell': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                }}
                pageSizeOptions={[9]}
                rowSelectionModel={selectedItem ? [selectedItem.id] : []}
                onRowSelectionModelChange={(newSelection) => {
                    const newSelectionArr = newSelection as string[]
                    const item = items.find((item) => item.id === newSelectionArr[0])
                    item && setSelectedItem(item)
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 9,
                        },
                    },
                }}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: () => (
                        <Box
                            component={'div'}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                bgcolor: colors.whiteLowOpacity,
                            }}
                        >
                            <Typography>{t('drawerPanels.dataItemEditPanel.noItems')}</Typography>
                        </Box>
                    ),
                }}
            />
        </Box>
    )
}

export default DataItemEditPanel
