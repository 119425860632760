import { Delete, DragIndicator } from '@mui/icons-material'
import { Box, Button, Card, IconButton, Tooltip, Typography } from '@mui/material'
import {
    AttributeCollectionValue,
    AttributeCollectionValueItem,
    DataItemChange,
    DataItemChangeType,
} from 'graphql/types'
import _ from 'lodash'
import { DragDropContext, Draggable, DraggableProvided, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import {
    getDiscriminatedItemFromAttributeValueUnion,
    getOrderedAttributeUnion,
    getOrderedAttributeValueUnion,
} from 'utils/dataType/functions'
import { AttributeValueUnion, DataItemUnion, createInitialAttributeValues } from 'utils/dataType/types'
import { v4 } from 'uuid'
import { AttributeValueEdit } from './AttributeValueEdit'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

const getCollectionValueItemFromAttributeValues = (
    id: string,
    values: AttributeValueUnion[],
): AttributeCollectionValueItem => {
    const change: DataItemChange = {
        changeType: DataItemChangeType.CREATE,
        timestamp: 0,
        user: { id: '', name: '' },
    }
    const tempDataItem: DataItemUnion = {
        id,
        published: true,
        created: change,
        updated: change,
        values,
    }

    const discriminatedDataItem = getDiscriminatedItemFromAttributeValueUnion(tempDataItem)

    return {
        ..._.omit(discriminatedDataItem, 'published', 'attributeCollectionValues', '__typename', 'created', 'updated'),
        __typename: 'AttributeCollectionValueItem',
    }
}

// This is getting a bit ugly due to nesting
export const AttributeCollectionValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue, subTypes } = props

    const { t } = useTranslation()

    if (attributeValue.__typename !== 'AttributeCollectionValue') return null
    if (!attribute || attribute.__typename !== 'AttributeCollection') return null

    const nestedType = subTypes.find((subType) => subType.id === attribute.dataType)

    if (!nestedType)
        return <Typography>{t('drawerPanels.dataItemDetailsPanel.attributeCollectionValueEdit.noSubType')}</Typography>

    const nestedAttributes = getOrderedAttributeUnion(nestedType)

    const { value } = attributeValue

    const override = (values: Partial<AttributeCollectionValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    const onDragEnd: OnDragEndResponder = (result) => {
        const { destination, source } = result
        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return
        if (destination.droppableId !== source.droppableId) return

        const list = Array.from(value)
        const [removed] = list.splice(source.index, 1)
        list.splice(destination.index, 0, removed)

        override({ value: list })
    }

    const onClickAddOption = () => {
        const item = getCollectionValueItemFromAttributeValues(v4(), createInitialAttributeValues(nestedAttributes))
        override({ value: [...value, { ...item }] })
    }

    const onClickDeleteOption = (attributeValue: AttributeCollectionValueItem) => {
        override({ value: value.filter((item) => item.id !== attributeValue.id) })
    }

    const renderAttributeValue = (item: AttributeCollectionValueItem, index: number) => {
        const nestedValues = getOrderedAttributeValueUnion(nestedType, item)

        const setNestedAttributeValue = (attributeValue: AttributeValueUnion) => {
            const newNestedValues = nestedValues.map((nestedValue) =>
                nestedValue.attributeID === attributeValue.attributeID ? attributeValue : nestedValue,
            )
            const newItem = getCollectionValueItemFromAttributeValues(item.id, newNestedValues)

            override({ value: value.map((nestedValue) => (nestedValue.id === newItem.id ? newItem : nestedValue)) })
        }

        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided: DraggableProvided) => (
                    <Card
                        key={item.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        component={Box}
                        display={'flex'}
                        flexDirection={'row'}
                        padding={'8px'}
                        marginBottom={'8px'}
                        alignItems={'flex-start'}
                        position={'relative'}
                        sx={{
                            ':hover': {
                                '> button': {
                                    display: 'flex',
                                },
                            },
                            '> button': {
                                display: 'none',
                            },
                            paddingY: '16px',
                            paddingRight: '16px',
                        }}
                    >
                        <DragIndicator fontSize="large" />
                        <Box display="flex" flexDirection={'column'} flex={1} gap={'8px'} marginLeft={'8px'}>
                            {nestedValues.map((nestedAttributeValue) => (
                                <AttributeValueEdit
                                    key={nestedAttributeValue.attributeID}
                                    {...props}
                                    attribute={nestedAttributes.find(
                                        (attribute) => attribute.id === nestedAttributeValue.attributeID,
                                    )}
                                    attributeValue={nestedAttributeValue}
                                    setAttributeValue={setNestedAttributeValue}
                                    hideDivider
                                    index={index}
                                />
                            ))}
                        </Box>
                        <Tooltip title={t('drawerPanels.dataItemDetailsPanel.attributeCollectionValueEdit.remove')}>
                            <IconButton
                                sx={{ position: 'absolute', bottom: 6, left: 6, color: '#d32f2f' }}
                                onClick={() => onClickDeleteOption(item)}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Card>
                )}
            </Draggable>
        )
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Droppable droppableId={'options'}>
                        {(provided) => (
                            <span {...provided.droppableProps} ref={provided.innerRef}>
                                {attributeValue.value.map(renderAttributeValue)}
                                {provided.placeholder}
                            </span>
                        )}
                    </Droppable>
                    {/* <FormHelperText sx={{ color: '#d32f2f', marginBottom: 1 }}>
                        {errors.find((error) => error.type === `innerCollectionEmpty${attribute.id}`)?.message ?? ''}
                    </FormHelperText> */}

                    <Button variant={'contained'} fullWidth onClick={onClickAddOption}>
                        {t('drawerPanels.dataItemDetailsPanel.attributeCollectionValueEdit.add')}
                    </Button>
                </Box>
            </DragDropContext>
        </>
    )
}
