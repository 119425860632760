import { Checkbox, Divider, FormControlLabel } from '@mui/material'
import { AttributeAsset } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeAssetEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    const { t } = useTranslation()

    if (selectedAttribute.__typename !== 'AttributeAsset') return null

    const { required } = selectedAttribute

    const override = (values: Partial<AttributeAsset>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={required ?? false}
                        onChange={(event) => override({ required: event.target.checked })}
                    />
                }
                label={t('drawerPanels.attributeDetailsPanel.attributeAssetEdit.required')}
            />
        </>
    )
}
