import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const urlify = require('urlify').create({
    addEToUmlauts: true,
    szToSs: true,
    spaces: '-',
    nonPrintable: '_',
    trim: true,
    toLower: true,
})

export const getParsedURL = (url: string) => encodeURI(url.replaceAll(' ', '-'))

export const getPrettyURL = (url: string) => {
    return url
        .trim()
        .replaceAll(/ +/g, '-')
        .toLowerCase()
        .replaceAll(/[^\w\d-]+/g, '') // get cleaned version without anything except lowercase alphanumerics and hyphens
}

export const getAutomaticURL = (url: string) => urlify(url)

export interface LinkHelperProps {
    title: string
    url: string
    setUrl: (url: string) => void
}
export const LinkHelper = (props: LinkHelperProps) => {
    const { title, url, setUrl } = props

    const { t } = useTranslation()

    const automaticURL = getAutomaticURL(title)
    const prettyURL = getPrettyURL(url)

    // offer automagic URL creation based on title
    if (title != '' && url === '' && automaticURL !== 'non-printable')
        return (
            <Stack direction={'row'} width={'100%'} alignItems={'center'}>
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                    <Typography style={{ marginRight: 'auto' }}>{t('components.linkHelper.autoUrl')}</Typography>
                    <Typography style={{ wordBreak: 'break-all', marginRight: 'auto', whiteSpace: 'pre-wrap' }}>
                        {automaticURL}
                    </Typography>
                </Box>
                <Button variant="outlined" onClick={() => setUrl(automaticURL)}>
                    {t('components.linkHelper.use')}
                </Button>
            </Stack>
        )

    // if url already looks good => no helper text
    if (url === prettyURL) return <></>

    // otherwise show prettified version and offer to replace with that
    return (
        <Stack direction={'row'} width={'100%'} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'column'} flex={1}>
                <Typography style={{ marginRight: 'auto' }}>{t('components.linkHelper.cleanUrl')}</Typography>
                <Typography style={{ wordBreak: 'break-all', marginRight: 'auto', whiteSpace: 'pre-wrap' }}>
                    {prettyURL}
                </Typography>
            </Box>
            <Button variant="outlined" onClick={() => setUrl(prettyURL)}>
                {t('components.linkHelper.use')}
            </Button>
        </Stack>
    )
}
