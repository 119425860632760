import i18n from 'i18next'
import Fetch from 'i18next-fetch-backend'
import { initReactI18next } from 'react-i18next'

i18n.use(Fetch)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: 'en',
        ns: 'translation',
        fallbackLng: 'de',
        preload: ['en', 'de', 'es'],
        keySeparator: '.', // we use keys in form messages.welcome
        initImmediate: false,
        interpolation: {
            escapeValue: false, // react already prevents xss
        },
        backend: {
            loadPath: process.env.PUBLIC_URL + '/customize/i18n/{{lng}}.json',
        },
        react: {
            useSuspense: true,
        },
    })

export default i18n
