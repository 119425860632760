import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const NotLoggedIn = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography>{t('pages.notLoggedIn.pleaseLogin')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => alert('not implemented')}>
                    {t('pages.notLoggedIn.goToLogin')}
                </Button>
            </Grid>
        </Grid>
    )
}

export default NotLoggedIn
