import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material'
import { AttributeNumber } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeNumberEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute, errors } = props

    const { t } = useTranslation()

    if (selectedAttribute.__typename !== 'AttributeNumber') return null

    const { defaultValue, integer, max, min } = selectedAttribute

    const override = (values: Partial<AttributeNumber>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    const errorDefault =
        errors.find((error) => error.type === 'innerDefaultValueMin') ??
        errors.find((error) => error.type === 'innerDefaultValueMax')

    const errorMinMax = errors.find((error) => error.type === 'innerMinMax')

    return (
        <>
            <Divider />
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeNumberEdit.defaultValue')}
                defaultValue={defaultValue}
                type="number"
                onChange={(event) => override({ defaultValue: parseInt(event.target.value) })}
                error={!!errorDefault}
                helperText={errorDefault?.message ?? ''}
            />
            <FormControlLabel
                control={<Checkbox value={integer} />}
                label={t('drawerPanels.attributeDetailsPanel.attributeNumberEdit.intOnly')}
                onChange={() => {
                    override({ integer: !Boolean(integer) })
                }}
                checked={Boolean(integer)}
            />
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeNumberEdit.minValue')}
                defaultValue={min}
                type="number"
                onChange={(event) => override({ min: parseInt(event.target.value) })}
                error={!!errorMinMax}
                helperText={errorMinMax?.message ?? ''}
            />
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeNumberEdit.maxValue')}
                defaultValue={max}
                type="number"
                onChange={(event) => override({ max: parseInt(event.target.value) })}
                error={!!errorMinMax}
                helperText={errorMinMax?.message ?? ''}
            />
        </>
    )
}
