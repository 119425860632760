import { Autocomplete, Box, Button, Chip, Grid, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface AssetsFolderEditPanelProps {
    tagList: string[]
    onCancel: () => void
    onSubmit: (title: string, tags: string[]) => void
}

interface AssetsFolderEditPanelInputs {
    Title: string
    Tags: string[]
}

const AssetsFolderEditPanel = (props: AssetsFolderEditPanelProps): JSX.Element => {
    const { onCancel, onSubmit, tagList } = props

    const { t } = useTranslation()

    const { handleSubmit, control } = useForm<AssetsFolderEditPanelInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Title: '',
            Tags: [],
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: AssetsFolderEditPanelInputs) => {
            onSubmit(data.Title, data.Tags)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{t('drawerPanels.assetsFolderCreatePanel.createFolder')}</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Title"
                    control={control}
                    rules={{
                        required: t('drawerPanels.assetsFolderCreatePanel.nameRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.assetsFolderCreatePanel.nameMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('drawerPanels.assetsFolderCreatePanel.name')}
                            inputProps={{ maxLength: 150 }}
                            variant={'filled'}
                            fullWidth={true}
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Tags"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            fullWidth
                            id="Tags"
                            options={tagList}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            onChange={(event, value) => {
                                field.onChange(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label={t('drawerPanels.assetsFolderCreatePanel.tags')}
                                />
                            )}
                        />
                    )}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOnSave}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}

export default AssetsFolderEditPanel
