import { Checkbox, Divider, FormControlLabel, TextField } from '@mui/material'
import { AttributeText } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeTextEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    const { t } = useTranslation()

    if (selectedAttribute.__typename !== 'AttributeText') return null

    const { defaultValue, multiLine, required } = selectedAttribute

    const override = (values: Partial<AttributeText>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeTextEdit.defaultValue')}
                defaultValue={defaultValue}
                onChange={(event) => override({ defaultValue: event.target.value })}
                multiline={multiLine ?? false}
                rows={multiLine ? 4 : 1}
                sx={{ flex: 1 }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={required ?? false}
                        onChange={(event) => override({ required: event.target.checked })}
                    />
                }
                label={t('drawerPanels.attributeDetailsPanel.attributeTextEdit.required')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={multiLine ?? false}
                        onChange={(event) => override({ multiLine: event.target.checked })}
                    />
                }
                label={t('drawerPanels.attributeDetailsPanel.attributeTextEdit.multiline')}
            />
        </>
    )
}
