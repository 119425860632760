import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import CMSHeader from 'components/CMSHeader'
import { WebsiteInput } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { NavigationRoutes } from 'utils/navigationRoutes'

type WebsiteSettingsForm = Omit<WebsiteInput, 'id' | 'createdAt'>

const WebsiteCreatePage = (): JSX.Element => {
    const websiteCreate = useStoreActions((actions) => actions.model.websiteCreate)
    const websiteList = useStoreState((state) => state.model.websiteList)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { handleSubmit, control } = useForm<WebsiteSettingsForm>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            name: '',
            previewURL: '',
            productionURL: '',
            elementDefinitions: '',
        },
    })

    const createWebsite = () => {
        handleSubmit((data: WebsiteSettingsForm) => {
            websiteCreate({ ...data, id: '', createdAt: new Date().getTime() })
            navigate(NavigationRoutes.PAGE_TREE)
        })()
    }

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />

            <Box display="flex" flexDirection="column" flex={1} p={2}>
                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                    <Typography variant="h6">{t('pages.websiteCreatePage.createWebsite')}</Typography>
                </Grid>
                <Grid container mb={2}>
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: t('pages.websiteCreatePage.nameRequired'),
                            maxLength: { value: 150, message: t('pages.websiteCreatePage.nameMaxLength') },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label={t('pages.websiteCreatePage.name')}
                                fullWidth={true}
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message ?? ''}
                            />
                        )}
                    />
                </Grid>
                <Grid container mb={2}>
                    <Controller
                        name="previewURL"
                        control={control}
                        rules={{
                            required: t('pages.websiteCreatePage.previewURLRequired'),
                            maxLength: { value: 150, message: t('pages.websiteCreatePage.previewURLMaxLength') },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label={t('pages.websiteCreatePage.previewURL')}
                                fullWidth={true}
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message ?? ''}
                            />
                        )}
                    />
                </Grid>
                <Grid container mb={2}>
                    <Controller
                        name="productionURL"
                        control={control}
                        rules={{
                            required: t('pages.websiteCreatePage.productionURLRequired'),
                            maxLength: { value: 150, message: t('pages.websiteCreatePage.productionURLMaxLength') },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label={t('pages.websiteCreatePage.productionURL')}
                                fullWidth={true}
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message ?? ''}
                            />
                        )}
                    />
                </Grid>
                <Grid container mb={2}>
                    <Controller
                        name="elementDefinitions"
                        control={control}
                        render={({ field }) => {
                            return (
                                <TextField
                                    multiline
                                    minRows={4}
                                    maxRows={20}
                                    {...field}
                                    fullWidth={true}
                                    label={t('pages.websiteCreatePage.elementDefinitions')}
                                    variant={'filled'}
                                />
                            )
                        }}
                    />
                </Grid>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Button variant="contained" onClick={createWebsite}>
                        {t('pages.websiteCreatePage.create')}
                    </Button>
                </Stack>
            </Box>
        </>
    )
}

export default WebsiteCreatePage
