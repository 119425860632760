import { Add, Delete } from '@mui/icons-material'
import { Box, Button, ClickAwayListener, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataType, DataTypeAttributeType, DataTypeCategory } from 'graphql/types'
import { UseDataTypeTabsReturn } from 'pages/SettingsPage/DataTypeTab/useDataTypeTab'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { DataTypeAttributeRow } from './DataTypeAttributeRow'
import { FabSubButton } from './FabSubButton'

export type DataTypeEditPanelProps = Omit<UseDataTypeTabsReturn, 'selectedItem'> & {
    selectedItem: DataType
}

const DataTypeEditPanel = (props: DataTypeEditPanelProps): JSX.Element | null => {
    const {
        selectedItem,
        setSelectedType,
        attributes,
        onClickSaveType,
        onClickDeleteType,
        selectedAttribute,
        onClickCreateAttribute,
        onDragEndAttribute,
        category,
        errors,
    } = props

    const [expandFab, setExpandFab] = useState(false)

    const { t } = useTranslation()

    const errorDuplicate = errors.find((e) => e.type === 'outerIdentifierDuplicate')
    const errorIdentifierEmpty = errors.find((e) => e.type === 'outerIdentifierEmpty')
    const errorNameEmpty = errors.find((e) => e.type === 'outerNameEmpty')

    const renderHeader = () => {
        return (
            <Box
                display="flex"
                flexDirection="row"
                flex={1}
                gap={1}
                paddingX={3}
                paddingY={1}
                bgcolor={colors.white}
                marginBottom={-1}
            >
                <Box sx={{ flex: 0.5 }} />
                <Box sx={{ flex: 4.5 }}>
                    <Typography fontWeight={700} fontSize={'24px'}>
                        {t('drawerPanels.dataTypeEditPanel.headers.identifier')}
                    </Typography>
                </Box>
                <Box sx={{ flex: 3 }}>
                    <Typography fontWeight={700} fontSize={'24px'}>
                        {t('drawerPanels.dataTypeEditPanel.headers.name')}
                    </Typography>
                </Box>
                <Box sx={{ flex: 3 }}>
                    <Typography fontWeight={700} fontSize={'24px'}>
                        {t('drawerPanels.dataTypeEditPanel.headers.type')}
                    </Typography>
                </Box>
                <Box width={category === DataTypeCategory.DATA_MODULE ? '80px' : '40px'} />
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} padding={2} gap={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mb={1}>
                <ClickAwayListener onClickAway={() => setExpandFab(false)}>
                    <Tooltip title={t('drawerPanels.dataTypeEditPanel.add')} placement="top">
                        <span>
                            <Button
                                variant="contained"
                                onClick={() => setExpandFab(!expandFab)}
                                disabled={selectedItem.id === ''}
                            >
                                <Add />
                            </Button>
                        </span>
                    </Tooltip>
                </ClickAwayListener>
                {!selectedAttribute && expandFab && (
                    <Box
                        display={'flex'}
                        sx={{ position: 'fixed', top: 180, right: 16 }}
                        flexWrap={'wrap'}
                        maxWidth={290}
                        gap={1}
                        bgcolor={colors.white}
                        borderRadius={1}
                        padding={1}
                        zIndex={5}
                    >
                        {Object.values(DataTypeAttributeType)
                            .filter(
                                (item) =>
                                    category !== DataTypeCategory.SUB_TYPE || item !== DataTypeAttributeType.COLLECTION,
                            )
                            .map((item) => (
                                <FabSubButton
                                    key={item}
                                    attributeType={item}
                                    onClickCreateAttribute={onClickCreateAttribute}
                                />
                            ))}
                    </Box>
                )}
                <Tooltip title={t('drawerPanels.dataTypeEditPanel.deleteDataType')} placement="top">
                    <span>
                        <Button
                            variant="contained"
                            color={'error'}
                            onClick={onClickDeleteType}
                            disabled={selectedItem.id === ''}
                        >
                            <Delete />
                        </Button>
                    </span>
                </Tooltip>
                <Button
                    variant="contained"
                    onClick={onClickSaveType}
                    disabled={errors.length > 0 && errors.find((e) => e.type.startsWith('outer')) !== undefined}
                >
                    {t('drawerPanels.dataTypeEditPanel.save')}
                </Button>
            </Stack>
            <TextField label={'ID'} value={selectedItem.id} disabled />
            <TextField
                label={t('drawerPanels.dataTypeEditPanel.identifier')}
                value={selectedItem.identifier}
                onChange={(change) => {
                    setSelectedType({ ...selectedItem, identifier: change.target.value })
                }}
                error={!!(errorDuplicate ?? errorIdentifierEmpty)}
                helperText={errorDuplicate?.message ?? errorIdentifierEmpty?.message ?? ''}
            />
            <TextField
                label={t('drawerPanels.dataTypeEditPanel.name')}
                value={selectedItem.name}
                onChange={(change) => {
                    setSelectedType({ ...selectedItem, name: change.target.value })
                }}
                error={!!errorNameEmpty}
                helperText={errorNameEmpty?.message ?? ''}
            />
            <TextField
                label={t('drawerPanels.dataTypeEditPanel.description')}
                value={selectedItem.description}
                onChange={(change) => setSelectedType({ ...selectedItem, description: change.target.value })}
            />
            {selectedItem?.id && (
                <>
                    <DragDropContext onDragEnd={onDragEndAttribute}>
                        {renderHeader()}
                        <Box maxHeight={'calc(100vh - 513px)'} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                            <Droppable droppableId={'attributes'}>
                                {(provided) => (
                                    <span {...provided.droppableProps} ref={provided.innerRef}>
                                        {attributes.map((attribute, index) => (
                                            <Draggable key={attribute.id} draggableId={attribute.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        key={attribute.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <DataTypeAttributeRow {...props} item={attribute} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </span>
                                )}
                            </Droppable>
                        </Box>
                    </DragDropContext>
                    {attributes.length === 0 && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            flex={1}
                            gap={1}
                            alignItems={'center'}
                            paddingX={3}
                            paddingY={10}
                            marginTop={-1}
                            sx={{
                                bgcolor: colors.whiteLowOpacity,
                                fontSize: '18px',
                            }}
                            justifyContent="center"
                        >
                            <Typography>{t('drawerPanels.dataTypeEditPanel.noItems')}</Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default DataTypeEditPanel
