import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import { ChangeEvent } from 'react'

export interface SelectControlProps {
    label: string
    valueName: string
    value: string
    radio: boolean
    options: { value: string; label: string }[]
    onValueChange: (valueName: string, newValue: string) => void
}

export const SelectControl = (props: SelectControlProps): JSX.Element => {
    const { valueName, value, onValueChange, label, options, radio } = props
    if (!!options) {
        return (
            <Box
                sx={{
                    width: '100%',
                    mt: 1,
                    mb: 2,
                    '& .MuiTextField-root': { width: '100%' },
                }}
            >
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
                    {radio && (
                        <RadioGroup
                            value={value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                onValueChange(valueName, e.currentTarget.value)
                            }}
                        >
                            {options.map((option) => {
                                return (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.label ? option.label : '???'}
                                    />
                                )
                            })}
                        </RadioGroup>
                    )}
                    {!radio && (
                        <Select
                            value={value}
                            label={label}
                            onChange={(e: SelectChangeEvent<string>) => {
                                onValueChange(valueName, e.target.value)
                            }}
                        >
                            {options.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    )}
                </FormControl>
            </Box>
        )
    }
    return <></>
}
