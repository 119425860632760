import { FiberManualRecord, VisibilityOff } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Grid, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import CMSHeader from 'components/CMSHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'store/hooks'
import colors from 'utils/colors'
import { UnpublishedItem, UnpublishedItemState, UnpublishedItemType } from 'utils/types'

const renderPublishItem = (
    unpublishItem: UnpublishedItem,
    selectedUnpublishedItem: UnpublishedItem[],
    unpublishItems: UnpublishedItem[],
    onToggle: (value: UnpublishedItem) => void,
): JSX.Element => {
    const findColor = (): string => {
        let color: string = colors.black
        if (unpublishItem.state === UnpublishedItemState.EDIT) color = colors.orange
        if (unpublishItem.state === UnpublishedItemState.UNPUBLISHED) color = colors.red
        return color
    }

    const isSelected = selectedUnpublishedItem.find((item) => item.id === unpublishItem.id) !== undefined
    let isDisabled = false
    if (unpublishItem.type === UnpublishedItemType.PAGE) {
        isDisabled =
            unpublishItem.parentPageId !== undefined &&
            unpublishItem.parentPageId !== null &&
            unpublishItem.parentPageId !== '' &&
            unpublishItems.find((item) => item.id === unpublishItem.parentPageId) !== undefined &&
            selectedUnpublishedItem.find((item) => item.id === unpublishItem.parentPageId) === undefined
    }

    return (
        <Grid
            key={unpublishItem.id}
            item
            container
            direction="column"
            position="relative"
            style={{ borderBottom: '1px solid #ccc' }}
        >
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={isDisabled}
                            checked={isSelected}
                            onClick={() => {
                                onToggle(unpublishItem)
                            }}
                        />
                    }
                    label={unpublishItem.label}
                />
            </Grid>
            <Grid item>{unpublishItem.url && <Typography variant={'body2'}>{unpublishItem.url}</Typography>}</Grid>
            {unpublishItem.state === UnpublishedItemState.EDIT ||
                (unpublishItem.state === UnpublishedItemState.UNPUBLISHED && (
                    <FiberManualRecord
                        style={{ color: findColor(), position: 'absolute', right: 6, top: 6, height: 8, width: 8 }}
                    />
                ))}
            {unpublishItem.state === UnpublishedItemState.DEACTIVATED && (
                <VisibilityOff style={{ position: 'absolute', right: 4, top: 3, height: 12, width: 12 }} />
            )}
        </Grid>
    )
}

export const PublishPage = (): JSX.Element => {
    const [selectedUnpublishedItem, setSeletecUnpublishedItem] = useState<UnpublishedItem[]>([])

    const { t } = useTranslation()

    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const publishItems = useStoreActions((actions) => actions.model.publishItems)
    const websiteList = useStoreState((state) => state.model.websiteList)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)

    const onToggle = (value: UnpublishedItem): void => {
        let newSelectedUnpublishedItem = [...selectedUnpublishedItem]
        const index = newSelectedUnpublishedItem.findIndex((i) => i.id === value.id)
        if (index === -1) {
            newSelectedUnpublishedItem.push(value)
        } else {
            const idsToDelete = [value.id]
            newSelectedUnpublishedItem = newSelectedUnpublishedItem.filter((unpublishItem: UnpublishedItem) => {
                for (const itemID of idsToDelete) {
                    if (
                        itemID === unpublishItem.id ||
                        (unpublishItem.parentPageId && unpublishItem.parentPageId === itemID)
                    ) {
                        idsToDelete.push(unpublishItem.id)
                        return false
                    }
                }
                return true
            })
        }
        setSeletecUnpublishedItem(newSelectedUnpublishedItem)
    }

    const blocksItems = unpublishedItems.filter((item: UnpublishedItem) => item.type === UnpublishedItemType.BLOCK)
    const pageItems = unpublishedItems.filter((item: UnpublishedItem) => item.type === UnpublishedItemType.PAGE)

    const onToggleAllPages = (): void => {
        let containsAllPages = true
        for (const page of pageItems) {
            if (selectedUnpublishedItem.find((item) => item.id === page.id) === undefined) {
                containsAllPages = false
                break
            }
        }
        if (containsAllPages) {
            setSeletecUnpublishedItem(
                selectedUnpublishedItem.filter((item) => {
                    return pageItems.find((page) => page.id === item.id) === undefined
                }),
            )
        } else {
            setSeletecUnpublishedItem([...selectedUnpublishedItem, ...pageItems])
        }
    }

    const containsAllPages = (): boolean => {
        for (const page of pageItems) {
            if (selectedUnpublishedItem.find((item) => item.id === page.id) === undefined) {
                return false
            }
        }
        return true
    }

    const onToggleAllBlocks = (): void => {
        let containsAllBlocks = true
        for (const block of blocksItems) {
            if (selectedUnpublishedItem.find((item) => item.id === block.id) === undefined) {
                containsAllBlocks = false
                break
            }
        }
        if (containsAllBlocks) {
            setSeletecUnpublishedItem(
                selectedUnpublishedItem.filter((item) => {
                    return blocksItems.find((block) => block.id === item.id) === undefined
                }),
            )
        } else {
            setSeletecUnpublishedItem([...selectedUnpublishedItem, ...blocksItems])
        }
    }

    const containsAllBlocks = (): boolean => {
        for (const block of blocksItems) {
            if (selectedUnpublishedItem.find((item) => item.id === block.id) === undefined) {
                return false
            }
        }
        return true
    }

    const onPublish = () => {
        publishItems(
            selectedUnpublishedItem.map((item: UnpublishedItem) => {
                let itemType = 'page'
                if (item.type === UnpublishedItemType.BLOCK) itemType = 'block'
                return {
                    itemID: item.id,
                    type: itemType,
                }
            }),
        )
    }

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <Box display="flex" flexDirection="column" p={2} maxWidth={800} marginX={'auto'}>
                <Typography variant="h5">{t('pages.publishPage.publishing')}</Typography>
                <Grid container justifyContent={'space-between'}>
                    {pageItems.length > 0 && (
                        <Grid
                            item
                            container
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            xs={12}
                            marginTop={'16px'}
                        >
                            <Tooltip
                                title={
                                    containsAllPages()
                                        ? t('pages.publishPage.unselectPages')
                                        : t('pages.publishPage.selectPages')
                                }
                                placement={'right'}
                            >
                                <FormControlLabel
                                    control={<Checkbox checked={containsAllPages()} onClick={onToggleAllPages} />}
                                    label={<Typography variant="h4">{t('pages.publishPage.pages')}</Typography>}
                                />
                            </Tooltip>
                            {pageItems.map((value: UnpublishedItem) =>
                                renderPublishItem(value, selectedUnpublishedItem, pageItems, onToggle),
                            )}
                        </Grid>
                    )}
                    {blocksItems.length > 0 && (
                        <Grid
                            item
                            container
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            xs={12}
                            marginTop={'16px'}
                        >
                            <Tooltip
                                title={
                                    containsAllBlocks()
                                        ? t('pages.publishPage.unselectBlocks')
                                        : t('pages.publishPage.selectBlocks')
                                }
                                placement={'right'}
                            >
                                <FormControlLabel
                                    control={<Checkbox checked={containsAllBlocks()} onClick={onToggleAllBlocks} />}
                                    label={<Typography variant="h4">{t('pages.publishPage.blocks')}</Typography>}
                                />
                            </Tooltip>
                            {blocksItems.map((value: UnpublishedItem) =>
                                renderPublishItem(value, selectedUnpublishedItem, blocksItems, onToggle),
                            )}
                        </Grid>
                    )}
                </Grid>
                {blocksItems.length > 0 || pageItems.length > 0 ? (
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                        <Button variant="contained" onClick={onPublish} disabled={selectedUnpublishedItem.length === 0}>
                            {t('pages.publishPage.publishSelected')}
                        </Button>
                    </Stack>
                ) : (
                    <Stack mt={3}>
                        <Typography variant="h6">{t('pages.publishPage.noUnpublished')}</Typography>
                    </Stack>
                )}
            </Box>
        </>
    )
}
