import { Button } from '@mui/material'
import { DataTypeAttributeType } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

export interface FabSubButtonProps {
    attributeType: DataTypeAttributeType
    onClickCreateAttribute: (attributeType: DataTypeAttributeType) => void
}

export const FabSubButton = (props: FabSubButtonProps) => {
    const { attributeType, onClickCreateAttribute } = props

    const { t } = useTranslation()

    return (
        <Button
            variant="contained"
            sx={{ height: '36px', color: colors.black, backgroundColor: colors.primaryHalfOpacity }}
            onClick={() => onClickCreateAttribute(attributeType)}
        >
            {/* Makes e.g. "TEXT" into "Text" */}
            {t(`common.types.${attributeType.charAt(0) + attributeType.toLowerCase().slice(1)}`)}
        </Button>
    )
}
