import gql from 'graphql-tag'

export const WEBSITE_CREATE = gql`
    mutation createWebsite($input: WebsiteInput!) {
        websiteCreate(input: $input) {
            id
            name
            previewURL
            productionURL
            elementDefinitions
            createdAt
            updatedAt
            updatedBy {
                id
                name
            }
        }
    }
`
