import { Box, CircularProgress } from '@mui/material'
import { default as CMSHeader } from 'components/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerGridProps, DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import PagePreview from 'components/PagePreview'
import BlockCreatePanel from 'drawerPanels/BlockCreatePanel'
import BlockDetailsPanel from 'drawerPanels/BlockDetailsPanel'
import BlockSettingsEditPanel from 'drawerPanels/BlockSettingsEditPanel'
import BlockTreePanel from 'drawerPanels/BlockTreePanel'
import { BlockVersion } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { getParsedPageElements } from 'utils/parseElementContent'
import { EditingDataType, PageElement } from 'utils/types'

export enum BlockListViewMode {
    PUBLISH = 'PUBLISH',
    SETTINGS = 'SETTINGS',
    BLOCKLIST = 'BLOCKLIST',
    ADD = 'ADD',
    EDIT = 'EDIT',
}

// FIXME: this is giga slow
// FIXME: and often has trouble with displaying the most up to date content (or any content at all for that matter)
// FIXME: and state updates just straight up dont work half the time

const BlockListPage = (): JSX.Element => {
    //data
    const drawerGridProps: DrawerGridProps = {}

    //hooks and data from store
    const navigate = useNavigate()

    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const currentlySelectedBlock = useStoreState((state) => state.model.currentlySelectedBlock)
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const blockList = useStoreState((state) => state.model.blockList)
    const blockData = useStoreState((state) => state.model.blockData)
    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)
    const websiteList = useStoreState((state) => state.model.websiteList)

    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)
    const loadBlock = useStoreActions((actions) => actions.model.loadBlock)
    const deleteBlock = useStoreActions((actions) => actions.model.deleteBlock)
    const duplicateBlock = useStoreActions((actions) => actions.model.duplicateBlock)
    const createBlock = useStoreActions((actions) => actions.model.createBlock)
    const saveBlock = useStoreActions((actions) => actions.model.saveBlock)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)

    const { t } = useTranslation()

    //internal state
    const [viewMode, setViewMode] = useState<BlockListViewMode>(BlockListViewMode.BLOCKLIST)
    const [pageElements, setPageElements] = useState<PageElement[]>([])

    // set page elements when we switch page
    useEffect(() => {
        setPageElements(getParsedPageElements(currentlySelectedBlock?.currentVersion.contents))
    }, [currentlySelectedBlock?.id])

    // set page elements when we click on view for a previous version
    const showBlockVersion = (version: BlockVersion) => {
        if (currentlySelectedBlock) {
            setPageElements(getParsedPageElements(version.contents))
        }
    }

    // add a new page version with the old contents
    const reactivateBlockVersion = (version: BlockVersion) => {
        if (currentlySelectedBlock) {
            saveBlock({
                blockID: currentlySelectedBlock.id,
                content: version.contents,
                identifier: version.id,
                name: version.name,
            })
            setPageElements(getParsedPageElements(version.contents))
        }
    }

    //functions
    const selectBlock = (id: string) => {
        if (currentlySelectedBlock === undefined || (currentlySelectedBlock && currentlySelectedBlock.id !== id)) {
            loadBlock(id)
        }
    }

    const onDeleteBlock = (id: string) => {
        if (confirm(t('pages.blockListPage.confirmDeleteBlock'))) {
            deleteBlock(id)
        }
    }

    const onDuplicateBlock = (id: string) => {
        duplicateBlock(id)
    }

    const onSaveBlock = (label: string, identifier: string) => {
        if (currentlySelectedBlock)
            saveBlock({
                blockID: currentlySelectedBlock.id,
                name: label,
                content: currentlySelectedBlock.currentVersion.contents,
                identifier,
            })
        setViewMode(BlockListViewMode.BLOCKLIST)
    }

    const onEditBlock = () => {
        if (currentlySelectedBlock) {
            startEditingPage({
                currentlyEditingData: {
                    id: currentlySelectedBlock.id,
                    identifier: currentlySelectedBlock.identifier,
                    label: currentlySelectedBlock.currentVersion.name,
                    // TODO: do we want to start editing with the currently VIEWED or the most up to date version? here and in pageTree
                    contents: currentlySelectedBlock.currentVersion.contents,
                    type: EditingDataType.BLOCK,
                },
                pageElementContents: getParsedPageElements(currentlySelectedBlock.currentVersion.contents),
            })
            navigate(NavigationRoutes.PAGE_EDIT)
        }
    }

    // Loading until user is loaded
    // if the user has a default website, wait for that to be loaded
    if (!loggedInUser || (loggedInUser.defaultWebsiteID !== '' && !selectedWebsite)) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }

    // if we are logged in, but no default website, just dont show the page UI but the header
    if (!selectedWebsite)
        return (
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
        )

    drawerGridProps.primary = (
        <BlockTreePanel
            blocks={blockList}
            onSelectBlock={selectBlock}
            onBlockCreate={() => setViewMode(BlockListViewMode.ADD)}
            selectedBlock={currentlySelectedBlock ? currentlySelectedBlock.id : ''}
        />
    )

    if (viewMode === BlockListViewMode.BLOCKLIST) {
        // page tree
        if (currentlySelectedBlock) {
            drawerGridProps.secondary = (
                <BlockDetailsPanel
                    block={currentlySelectedBlock}
                    onEditBlock={onEditBlock}
                    onEditSettings={() => setViewMode(BlockListViewMode.EDIT)}
                    onDuplicate={onDuplicateBlock}
                    onDelete={onDeleteBlock}
                    showBlockVersion={showBlockVersion}
                    reactivateBlockVersion={reactivateBlockVersion}
                />
            )
        }
        // primaryDrawerWidth={DrawerWidth.SMALL}
        // secondaryDrawerWidth={DrawerWidth.MEDIUM}
        drawerGridProps.collapsible = true
    }
    // edit view
    else if (viewMode === BlockListViewMode.EDIT) {
        drawerGridProps.secondary = (
            <BlockSettingsEditPanel
                saveIsDisabled={savingCurrentVersionDisabled}
                label={currentlySelectedBlock?.currentVersion.name ?? ''}
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                identifier={currentlySelectedBlock?.identifier ?? ''}
                onSubmit={onSaveBlock}
            />
        )

        drawerGridProps.secondaryWidth = DrawerWidth.MEDIUM
        drawerGridProps.collapsible = false
        drawerGridProps.primaryDisabled = true
    }

    // add view
    else if (viewMode === BlockListViewMode.ADD) {
        drawerGridProps.secondary = (
            <BlockCreatePanel
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                onSubmit={(label: string) => {
                    createBlock({
                        name: label,
                        websiteID: selectedWebsite.id,
                    })
                    setViewMode(BlockListViewMode.BLOCKLIST) //TODO: find a better way of closing the panel after creating
                }}
            />
        )

        drawerGridProps.secondaryWidth = DrawerWidth.MEDIUM
        drawerGridProps.collapsible = false
        drawerGridProps.primaryDisabled = true
    }

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid
                {...drawerGridProps}
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        disabled={true}
                        blocks={blockData}
                        pageElements={pageElements}
                        activeBreakpoint={{
                            identifier: 'xl',
                            name: 'xl',
                            editorCssWidth: '100%',
                            fromWidthPixels: 1536,
                        }}
                    />
                }
            />
        </>
    )
}

export default BlockListPage
