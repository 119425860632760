import { Autocomplete, Box, TextField, TextFieldProps, Tooltip, Typography } from '@mui/material'
import { Asset, AssetFolder, AssetType, Page } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store/hooks'
import { resolveFolderPath, resolvePageURL } from 'utils/linkResolver'
import { AutocompleteNestedItem, AutocompleteNestedProps, useAutocompleteNested } from './UseAutocompleteNested'

export const AutocompleteNested = <T extends unknown>(props: AutocompleteNestedProps<T>) => {
    const hookReturn = useAutocompleteNested(props)

    const { t } = useTranslation()

    return (
        <Tooltip title={t('components.autocompleteNested.tooltip')} placement="top-start">
            <Autocomplete {...hookReturn} />
        </Tooltip>
    )
}

type AutocompleteNestedWrapperProps<T, DefaultValueType = T> = Omit<
    AutocompleteNestedProps<T>,
    'options' | 'renderOption' | 'renderInput' | 'defaultValue'
> & { inputProps?: TextFieldProps; defaultValue?: DefaultValueType }

export const AutocompleteNestedPage = (props: AutocompleteNestedWrapperProps<Page>) => {
    const pages = useStoreState((state) => state.model.pageList)

    const pageToOption = (page: Page) => {
        const pageSettings = page.currentVersion.pageSettings
        const resolved = resolvePageURL(page.id, pages, true)

        return {
            item: page,
            group: resolved,
            key: pageSettings.urlTitle,
            value: pageSettings.htmlTitle,
        }
    }

    const options: AutocompleteNestedItem<Page>[] = pages.map(pageToOption)

    return (
        <AutocompleteNested<Page>
            options={options}
            renderInput={(params) => <TextField {...params} {...props.inputProps} />}
            renderOption={(props, option) => (
                <Box component="li" display={'flex'} flexDirection={'column'} {...props}>
                    <Typography alignSelf={'flex-start'}>{option.value}</Typography>
                    <Typography variant="body2" alignSelf={'flex-start'}>
                        {option.key}
                    </Typography>
                </Box>
            )}
            // override with any passed props
            {...props}
            // generate default value item
            defaultValue={props.defaultValue && pageToOption(props.defaultValue)}
            disableCloseOnSelect
        />
    )
}

export const AutocompleteNestedAsset = (props: AutocompleteNestedWrapperProps<Asset | AssetFolder, Asset>) => {
    const assets = useStoreState((state) => state.model.assets)
    const folders = useStoreState((state) => state.model.folders)

    const { t } = useTranslation()

    const assetToOption = (asset: Asset) => {
        const resolved = resolveFolderPath(asset.folder ?? undefined, folders)

        // set typename for later discrimination
        asset.__typename = 'Asset'

        return {
            item: asset,
            group: resolved,
            key: asset.url.split('/').at(-1) ?? '',
            value: asset.name,
        }
    }

    // each option contains either a folder or an asset
    // with the item being the thumbnail url for renderOption
    const assetOptions: AutocompleteNestedItem<Asset | AssetFolder>[] = assets.map(assetToOption)
    const folderOptions: AutocompleteNestedItem<Asset | AssetFolder>[] = folders.map((folder) => {
        const resolved = resolveFolderPath(folder.parentFolder ?? undefined, folders)

        // set typename for later discrimination
        folder.__typename = 'AssetFolder'

        return {
            item: folder,
            group: resolved,
            key: folder.name,
            value: '', // causes folders to always sort first and theres nothing sensible here anyhow
        }
    })

    return (
        <AutocompleteNested<Asset | AssetFolder>
            options={[...assetOptions, ...folderOptions]}
            getOptionLabel={(option) => (option.value ? option.value : option.key)}
            renderInput={(params) => <TextField {...params} {...props.inputProps} />}
            renderOption={(props, option) => {
                let url = ''
                if (option.item.__typename === 'Asset') {
                    const asset = option.item
                    if (asset.type === AssetType.IMAGE) url = asset.thumbnailUrl ?? asset.url
                    else if (asset.type === AssetType.PDF) url = '/img/pdf.png'
                    else '/img/file.png'
                } else if (option.item.__typename === 'AssetFolder') url = '/img/folder.png'

                return (
                    <Box component="li" display={'flex'} flexDirection={'row'} {...props}>
                        <img
                            src={url}
                            alt={t('components.autocompleteNested.alt')}
                            width={48}
                            height={48}
                            style={{ marginRight: 8, objectFit: 'contain' }}
                        />
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography alignSelf={'flex-start'}>
                                {option.value ? option.value : t('components.autocompleteNested.folder')}
                            </Typography>
                            <Typography variant="body2" alignSelf={'flex-start'} noWrap>
                                {option.key}
                            </Typography>
                        </Box>
                    </Box>
                )
            }}
            // override with any passed props
            {...props}
            // generate default value item
            defaultValue={props.defaultValue && assetToOption(props.defaultValue)}
            disableCloseOnSelect
            isFinalItem={(item) => item?.__typename === 'Asset'}
        />
    )
}
