import { Box, Button, Stack, Typography } from '@mui/material'
import { CollectionControl, CollectionItemData } from 'drawerPanels/EditElementPanel/DialogElements/CollectionControl'
import { LinkControl } from 'drawerPanels/EditElementPanel/DialogElements/LinkControl'
import { MediaControl } from 'drawerPanels/EditElementPanel/DialogElements/MediaControl'
import { ElementDefinition, FieldDefinition, FieldType } from 'elementDefinitions'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'store/hooks'
import { LinkData, MediaData } from 'utils/types'
import { Headline } from './DialogElements/Headline'
import { InfoText } from './DialogElements/InfoText'
import { SelectControl } from './DialogElements/SelectControl'
import { TextareaControl } from './DialogElements/TextareaControl'
import { ToggleControl } from './DialogElements/ToggleControl'

const EditElementPanel = (): JSX.Element => {
    const cancelEditElement = useStoreActions((actions) => actions.model.cancelEditElement)
    const changeElementValue = useStoreActions((actions) => actions.model.changeElementValue)
    const elementDefinitionList = useStoreState((state) => state.model.elementDefinitionList)
    const currentlyEditingElement = useStoreState((state) => state.model.currentlyEditingElement)
    const pageList = useStoreState((state) => state.model.pageList)
    const assets = useStoreState((state) => state.model.assets)

    const { t } = useTranslation()

    const type: string = currentlyEditingElement ? currentlyEditingElement.type : ''

    const renderField = (field: FieldDefinition, index: number): JSX.Element => {
        if (field.type === FieldType.TEXT) {
            return (
                <TextareaControl
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    lines={field.lines}
                    multiline={field.multiLine}
                    value={currentlyEditingElement?.data[field.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.LINK) {
            let val: LinkData = { url: '' }
            if (currentlyEditingElement?.data[field.valueName]) {
                val = currentlyEditingElement?.data[field.valueName] as LinkData
            }

            return (
                <LinkControl
                    assets={assets}
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    value={val}
                    pageList={pageList}
                    disableExternal={field.disableExternal}
                    disableInternal={field.disableInternal}
                    disableFile={field.disableFile}
                    onValueChange={(valueName: string, newValue: LinkData) => {
                        changeElementValue({ newValue: newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.MEDIA) {
            let val: MediaData = { __qbd_fileId: '', __qbd_altText: '' }
            if (currentlyEditingElement?.data[field.valueName]) {
                val = currentlyEditingElement?.data[field.valueName] as MediaData
            }

            return (
                <MediaControl
                    assets={assets}
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    value={val}
                    onValueChange={(valueName: string, newValue: MediaData) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.TOGGLE) {
            return (
                <ToggleControl
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    value={currentlyEditingElement?.data[field.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.SELECT) {
            return (
                <SelectControl
                    label={field.label}
                    key={field.label + index}
                    valueName={field.valueName}
                    options={field.options}
                    radio={field.control === 'radio'}
                    value={currentlyEditingElement?.data[field.valueName] as string}
                    onValueChange={(valueName: string, newValue: string) => {
                        changeElementValue({ newValue, valueName })
                    }}
                />
            )
        } else if (field.type === FieldType.INFO_TEXT) {
            return <InfoText label={field.label} key={field.label + index} />
        } else if (field.type === FieldType.HEADLINE) {
            return <Headline text={field.text} key={field.text + index} />
        } else if (field.type === FieldType.COLLECTION) {
            const currenltyEditingCollectionData = currentlyEditingElement?.data[
                field.valueName
            ] as CollectionItemData[]
            return (
                <CollectionControl
                    key={field.title + index}
                    field={field}
                    collectionItems={currenltyEditingCollectionData}
                />
            )
        }

        return <></>
    }

    let elementDefinition: ElementDefinition | undefined
    if (type != '__qbd_block') {
        elementDefinition = elementDefinitionList.find((element) => element.identifier == type)
    } else {
        elementDefinition = {
            fields: [],
            name: 'Block',
            identifier: 'laksdjfl',
            initialData: {},
        }
    }

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                boxShadow={'0px 0px 2px 0px rgba(0,0,0,1)'}
                paddingX={1}
                marginBottom={1}
                paddingBottom={1}
            >
                <Typography variant="h6">
                    {elementDefinition?.name ?? t('drawerPanels.editElementPanel.notFound')}
                </Typography>
                <Button variant="contained" onClick={() => cancelEditElement()}>
                    {t('drawerPanels.editElementPanel.close')}
                </Button>
            </Stack>
            <Box display={'flex'} flexDirection={'column'} flex={1} overflow={'auto'} paddingX={1}>
                {elementDefinition && elementDefinition.fields.map(renderField)}
            </Box>
        </Box>
    )
}

export default EditElementPanel
