import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface BlockCreatePanelProps {
    onCancel: () => void
    onSubmit: (title: string) => void
}

interface BlockCreateInputs {
    Title: string
}

const BlockCreatePanel = (props: BlockCreatePanelProps): JSX.Element => {
    const { onCancel, onSubmit } = props

    const { t } = useTranslation()

    const { handleSubmit, control } = useForm<BlockCreateInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Title: '',
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: BlockCreateInputs) => {
            onSubmit(data.Title)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{t('drawerPanels.blockCreatePanel.newBlock')}</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Title"
                    control={control}
                    rules={{
                        required: t('drawerPanels.blockCreatePanel.nameRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.blockCreatePanel.nameMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('drawerPanels.blockCreatePanel.name')}
                            inputProps={{ maxLength: 150 }}
                            fullWidth={true}
                            {...field}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOnSave}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}

export default BlockCreatePanel
