import { Tab, Tabs } from '@mui/material'
import CMSHeader from 'components/CMSHeader'
import { DataTypeCategory } from 'graphql/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { DataTypeTab } from './DataTypeTab/DataTypeTab'
import { GeneralSettingsTab } from './GeneralSettingsTab'

export const SettingsPage = (): JSX.Element => {
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const websiteList = useStoreState((state) => state.model.websiteList)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)

    const { t } = useTranslation()

    const tabs = [
        t('pages.settingsPage.tabs.general'),
        t('pages.settingsPage.tabs.elements'),
        t('pages.settingsPage.tabs.modules'),
        t('pages.settingsPage.tabs.subTypes'),
    ]

    const params = useParams<Record<string, string>>()
    const index = tabs.indexOf(params['tab'] ?? '')

    const [tab, setTab] = useState(index !== -1 ? index : 0)

    history.replaceState('', '', `#${NavigationRoutes.SETTINGS.replace(':tab', tabs[tab])}`)

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <Tabs
                value={tab}
                onChange={(_, tab) => setTab(tab)}
                role="navigation"
                centered
                sx={{ bgcolor: 'background.paper', boxShadow: '0px 0px 2px 0px rgba(0,0,0,1)' }}
            >
                <Tab label={t('pages.settingsPage.tabs.general')} />
                <Tab label={t('pages.settingsPage.tabs.elements')} />
                <Tab label={t('pages.settingsPage.tabs.modules')} />
                <Tab label={t('pages.settingsPage.tabs.subTypes')} />
            </Tabs>
            {tab === 0 && <GeneralSettingsTab />}
            {tab === 1 && <DataTypeTab category={DataTypeCategory.ELEMENT_DEFINITION} />}
            {tab === 2 && <DataTypeTab category={DataTypeCategory.DATA_MODULE} />}
            {tab === 3 && <DataTypeTab category={DataTypeCategory.SUB_TYPE} />}
        </>
    )
}
