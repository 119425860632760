import { Button, Stack, TextField } from '@mui/material'
import { DataItemEditPanelProps } from 'drawerPanels/DataItemEditPanel/DataItemEditPanel'
import { UseDataItemPageReturn } from 'pages/DataItemPage/UseDataItemPage'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { AttributeUnion, AttributeValueUnion, DataItemUnion } from 'utils/dataType/types'
import { AttributeValueEdit } from './AttributeValueEdit'

export type DataItemDetailsPanelProps = Omit<DataItemEditPanelProps, 'selectedItem'> & { selectedItem: DataItemUnion }
export type AttributeValueEditProps = UseDataItemPageReturn & {
    attribute: AttributeUnion | undefined
    attributeValue: AttributeValueUnion
    setAttributeValue: (attributeValue: AttributeValueUnion) => void
    hideDivider?: boolean
    index: number
}

const DataItemDetailsPanel = (props: DataItemDetailsPanelProps): JSX.Element | null => {
    const { onClickSaveItem, onClickCancelItem, selectedItem, setSelectedItem, attributes, errors } = props

    const { t } = useTranslation()

    const setAttributeValue = (value: AttributeValueUnion) =>
        setSelectedItem({
            id: selectedItem.id,
            published: selectedItem.published,
            created: selectedItem.created,
            updated: selectedItem.updated,
            values: selectedItem.values.map((item) => (item.attributeID === value.attributeID ? value : item)),
        })

    return (
        <Stack direction="column" p={2} position={'relative'} gap={2}>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={'16px'}
                position={'sticky'}
                top={0}
                zIndex={20}
                bgcolor={colors.lightGray}
                padding={2}
                margin={-2}
                marginBottom={-1}
            >
                <Button variant="contained" color={'secondary'} fullWidth onClick={onClickCancelItem}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" fullWidth onClick={onClickSaveItem} disabled={errors.length > 0}>
                    {t('common.save')}
                </Button>
            </Stack>
            <TextField label={'ID'} value={selectedItem.id} disabled />
            {selectedItem.values.map((attributeValue) => (
                <AttributeValueEdit
                    key={attributeValue.attributeID}
                    {...props}
                    attribute={attributes.find((attribute) => attribute.id === attributeValue.attributeID)}
                    attributeValue={attributeValue}
                    setAttributeValue={setAttributeValue}
                    index={-1}
                />
            ))}
        </Stack>
    )
}

export default DataItemDetailsPanel
