import { Autocomplete, Checkbox, FormControlLabel, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { AutocompleteNestedPage } from 'components/AutocompleteNested/AutocompleteNested'
import { AttributeLinkValue } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkOption, getLinkOptionFromValue, shouldLinkShowOption } from 'utils/dataType/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeLinkValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue, pages, assets, errors, index } = props

    const { t } = useTranslation()

    if (attributeValue.__typename !== 'AttributeLinkValue') return null
    if (!attribute || attribute.__typename !== 'AttributeLink') return null

    const { value, newWindow } = attributeValue

    const override = (values: Partial<AttributeLinkValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    const firstValidOption = Object.keys(LinkOption)
        .filter((item) => !Number.isNaN(item))
        .filter((item) => shouldLinkShowOption(attribute, item as LinkOption))[0] as LinkOption

    const [tab, setTab] = useState<LinkOption>(firstValidOption)

    // set initially selected tab if we already have a value
    useEffect(() => {
        if (value) setTab(getLinkOptionFromValue(value))
    }, [value])

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error = errors.find((error) => error.type === `innerLinkEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`)

    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={tab}
                exclusive
                size="small"
                fullWidth
                onChange={(_, value: LinkOption) => {
                    // change tab and reset value
                    if (value !== null) {
                        setTab(value)
                        override({ value: '' })
                    }
                }}
            >
                {Object.keys(LinkOption)
                    .filter((item) => !Number.isNaN(item))
                    .map((item) => (
                        <ToggleButton
                            key={item}
                            value={item}
                            disabled={!shouldLinkShowOption(attribute, item as LinkOption)}
                        >
                            {t(`drawerPanels.dataItemDetailsPanel.attributeLinkValueEdit.${item}`)}
                        </ToggleButton>
                    ))}
            </ToggleButtonGroup>
            {/* TODO: This is throwing uncontrolled/invalid option errors but works fine */}
            {tab === LinkOption.INTERNAL && (
                <AutocompleteNestedPage
                    defaultValue={pages.find((item) => item.id === value)}
                    onChange={(_, value) => override({ value: value?.item.id ?? '' })}
                    inputProps={{ label: attribute.common.name, error: !!error, helperText: error?.message }}
                />
            )}
            {tab === LinkOption.EXTERNAL && (
                <TextField
                    label={attribute.common.name}
                    value={value}
                    onChange={(event) => override({ value: event.target.value })}
                    error={!!error}
                    helperText={error?.message ?? ''}
                />
            )}
            {tab === LinkOption.FILE && (
                <Autocomplete
                    options={assets}
                    getOptionLabel={(option) => option.name}
                    defaultValue={assets.find((item) => item.id === value)}
                    onChange={(_, value) => override({ value: value?.id ?? '' })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={attribute.common.name}
                            error={!!error}
                            helperText={error?.message ?? ''}
                        />
                    )}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox checked={newWindow} onChange={(event) => override({ newWindow: event.target.checked })} />
                }
                label={t('drawerPanels.dataItemDetailsPanel.attributeLinkValueEdit.newWindow')}
            />
        </>
    )
}
