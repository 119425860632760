import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export enum Language {
    ENGLISH = 'en',
    GERMAN = 'de',
    SPANISH = 'es',
}

export interface UseLanguageReturn {
    language: Language
    onChangeLanguage: (language: Language) => void
}

const languageKey = 'language'

const useLanguage = () => {
    const { i18n } = useTranslation()

    const [language, setLanguage] = useState<Language>(i18n.language as Language)

    const onChangeLanguage = (language: Language) => {
        setLanguage(language)
        i18n.changeLanguage(language)
        localStorage.setItem(languageKey, language)
    }

    useEffect(() => {
        const language = localStorage.getItem(languageKey)
        if (language) onChangeLanguage(language as Language)
    }, [])

    return {
        language,
        onChangeLanguage,
    }
}

export default useLanguage
