import { TextField } from '@mui/material'
import { AttributeNumberValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeNumberValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue, errors, index } = props

    if (attributeValue.__typename !== 'AttributeNumberValue') return null
    if (!attribute || attribute.__typename !== 'AttributeNumber') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeNumberValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error =
        errors.find((error) => error.type === `innerNumberEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`) ??
        errors.find((error) => error.type === `innerNumberMin${attribute.id}${index >= 0 ? '-' + index : ''}`) ??
        errors.find((error) => error.type === `innerNumberMax${attribute.id}${index >= 0 ? '-' + index : ''}`) ??
        errors.find((error) => error.type === `innerNumberInteger${attribute.id}${index >= 0 ? '-' + index : ''}`)

    return (
        <TextField
            label={attribute.common.name}
            value={value.toString()}
            type="number"
            onChange={(event) => override({ value: parseFloat(event.target.value) })}
            error={!!error}
            helperText={error?.message ?? ''}
        />
    )
}
