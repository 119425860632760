import {
    Delete,
    Edit,
    FileCopy,
    History,
    Search,
    Settings,
    SettingsBackupRestore,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { Page, PageVersion } from 'graphql/types'
import { TFunction } from 'i18next'
import { cloneDeep } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface PageDetailsPanelrops {
    onEditPage: (page: Page) => void
    onDeactivate: (pageID: string) => void
    onEditPageSettings: (pageID: string) => void
    onDuplicate: (pageID: string) => void
    onDelete: (pageID: string) => void
    showPageVersion: (version: PageVersion) => void
    reactivatePageVersion: (version: PageVersion) => void
    page: Page
}

const timeFormat = 'dd.MM.yyyy HH:mm'

const getLastPublished = (page: Page): string => {
    const pageHistoryVersions = cloneDeep(page.historyVersions)
    for (const pageVersion of pageHistoryVersions.sort((a, b) => b.updatedAt - a.updatedAt)) {
        //TODO: move state in pageversion
        if (pageVersion.state === 'PUBLISHED') {
            return format(new Date(pageVersion.updatedAt), timeFormat)
        }
    }

    return '-'
}

const renderVersionEntry = (
    version: PageVersion,
    isCurrentVersion: boolean,
    showPageVersion: (version: PageVersion) => void,
    reactivatePageVersion: (version: PageVersion) => void,
    t: TFunction<'translation', undefined>,
) => {
    return (
        <>
            <Box display="flex" flexDirection="row" flex={1}>
                <Grid container flexDirection={'row'} alignItems={'center'}>
                    <Grid xs={10} item>
                        <Typography>{format(new Date(version.updatedAt), timeFormat)}</Typography>
                        <Typography>{version.updatedBy.name}</Typography>
                    </Grid>
                    <Grid xs={2} item flexDirection="column">
                        <Tooltip title={t('drawerPanels.pageDetailsPanel.show')} placement="right">
                            <Button variant="text" onClick={() => showPageVersion(version)}>
                                <Search />
                            </Button>
                        </Tooltip>
                        {!isCurrentVersion ? (
                            <Tooltip title={t('drawerPanels.pageDetailsPanel.reactivate')} placement="right">
                                <Button variant="text" onClick={() => reactivatePageVersion(version)}>
                                    <SettingsBackupRestore />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button style={{ opacity: 0 }} disabled>
                                <SettingsBackupRestore />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </>
    )
}

const PageDetailsPanel = (props: PageDetailsPanelrops): JSX.Element => {
    const {
        onEditPage,
        onDeactivate,
        onEditPageSettings,
        onDuplicate,
        onDelete,
        page,
        reactivatePageVersion,
        showPageVersion,
    } = props

    const { t } = useTranslation()

    const lastPublished = getLastPublished(page)

    const [displayPageVersion, setDisplayPageVersion] = useState<boolean>(false)

    useEffect(() => {
        setDisplayPageVersion(false)
    }, [page])

    return (
        <Grid container direction="column" p={2} spacing={1}>
            <Grid container item direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                    <Typography variant="h6">{page.currentVersion.pageSettings.htmlTitle}</Typography>
                </Grid>
                <Grid item xs={2} justifyItems={'flex-end'}>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.edit')} placement={'right'}>
                        <IconButton
                            onClick={() => {
                                onEditPage(page)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.lastChange')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.lastChange')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    {format(new Date(page.currentVersion.updatedAt), timeFormat)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.changedBy')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.changedBy')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={page.currentVersion.updatedBy.name} placement="left">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '130px',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            {page.currentVersion.updatedBy.name}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.lastPublish')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.lastPublish')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{lastPublished}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.status')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.status')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{t(`common.${page.currentVersion.state}`)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={2}>
                    <Tooltip
                        title={
                            page.deactivated
                                ? t('drawerPanels.pageDetailsPanel.activate')
                                : t('drawerPanels.pageDetailsPanel.deactivate')
                        }
                    >
                        <IconButton onClick={() => onDeactivate(page.id)}>
                            {!page.deactivated && <Visibility />}
                            {page.deactivated && <VisibilityOff />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={10} display="flex" alignContent="end">
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.settings')}>
                        <IconButton onClick={() => onEditPageSettings(page.id)}>
                            <Settings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.duplicate')}>
                        <IconButton onClick={() => onDuplicate(page.id)}>
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.delete')}>
                        <IconButton onClick={() => onDelete(page.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.versionHistory')}>
                        <IconButton onClick={() => setDisplayPageVersion(!displayPageVersion)}>
                            <History />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {displayPageVersion && (
                <Grid container item direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('drawerPanels.pageDetailsPanel.versions')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {renderVersionEntry(page.currentVersion, true, showPageVersion, reactivatePageVersion, t)}
                    </Grid>
                    {page.historyVersions.slice(1).map((pageVersion: PageVersion, index: number) => {
                        return (
                            <Fragment key={pageVersion.ID}>
                                {index > 0 && <Divider />}
                                {renderVersionEntry(pageVersion, false, showPageVersion, reactivatePageVersion, t)}
                            </Fragment>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}

export default PageDetailsPanel
