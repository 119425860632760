import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import { AutocompleteNestedPage } from 'components/AutocompleteNested/AutocompleteNested'
import { Asset, Page } from 'graphql/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkOption, getLinkOptionFromLinkData } from 'utils/dataType/types'
import { LinkData } from 'utils/types'

export interface LinkControlProps {
    label: string
    valueName: string
    value: LinkData
    disableInternal?: boolean
    disableExternal?: boolean
    disableFile?: boolean
    pageList: Page[]
    assets: Asset[]
    onValueChange: (valueName: string, newValue: LinkData) => void
}

export const LinkControl = (props: LinkControlProps): JSX.Element => {
    const { valueName, value, onValueChange, disableExternal, disableFile, disableInternal, label, pageList, assets } =
        props

    const [tab, setTab] = useState<LinkOption>(getLinkOptionFromLinkData(value))

    const { t } = useTranslation()

    return (
        <Box
            sx={{
                width: '100%',
                mt: 1,
                mb: 2,
                '& .MuiTextField-root': { width: '100%' },
            }}
        >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} mb={1}>
                <Typography>{label}</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={tab}
                    exclusive
                    size="small"
                    onChange={(_, value) => {
                        // change tab and reset value
                        if (value !== null) {
                            setTab(value)
                            onValueChange(valueName, { ...value, url: '' })
                        }
                    }}
                >
                    {Object.keys(LinkOption)
                        .filter((item) => !Number.isNaN(item))
                        .map((item) => (
                            <ToggleButton
                                key={item}
                                value={item}
                                disabled={
                                    ((item as LinkOption) === LinkOption.INTERNAL && disableInternal) ||
                                    ((item as LinkOption) === LinkOption.EXTERNAL && disableExternal) ||
                                    ((item as LinkOption) === LinkOption.FILE && disableFile)
                                }
                            >
                                {t(`drawerPanels.dataItemDetailsPanel.attributeLinkValueEdit.${item}`)}
                            </ToggleButton>
                        ))}
                </ToggleButtonGroup>
            </Grid>
            {tab === LinkOption.INTERNAL && (
                <AutocompleteNestedPage
                    defaultValue={pageList.find((item) => item.id === value.__qbd_pageId)}
                    onChange={(_, val) => {
                        onValueChange(valueName, {
                            ...val,
                            url: undefined,
                            __qbd_pageId: val?.item.id,
                            __qbd_fileId: undefined,
                        })
                    }}
                    inputProps={{
                        label: valueName,
                    }}
                />
            )}
            {tab === LinkOption.EXTERNAL && (
                <TextField
                    label={valueName}
                    value={value.url}
                    fullWidth
                    onChange={(event) =>
                        onValueChange(valueName, {
                            ...value,
                            url: event.target.value,
                            __qbd_pageId: undefined,
                            __qbd_fileId: undefined,
                        })
                    }
                />
            )}
            {tab === LinkOption.FILE && (
                <Autocomplete
                    options={assets}
                    getOptionLabel={(option) => option.name}
                    value={assets.find((item) => item.id === value.__qbd_fileId) ?? null}
                    onChange={(_, val) => {
                        onValueChange(valueName, {
                            ...val,
                            url: undefined,
                            __qbd_pageId: undefined,
                            __qbd_fileId: val?.id,
                        })
                    }}
                    renderInput={(params) => <TextField {...params} label={valueName} />}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value.newWindow}
                        onChange={(event) => onValueChange(valueName, { ...value, newWindow: event.target.checked })}
                    />
                }
                label={t('drawerPanels.dataItemDetailsPanel.attributeLinkValueEdit.newWindow')}
            />
        </Box>
    )
}
