import { Box, Grid, TextField, Typography } from '@mui/material'
import { AutocompleteNestedAsset } from 'components/AutocompleteNested/AutocompleteNested'
import { Asset } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { MediaData } from 'utils/types'

export interface MediaControlProps {
    label: string
    valueName: string
    value: MediaData
    assets: Asset[]
    onValueChange: (valueName: string, newValue: MediaData) => void
}

export const MediaControl = (props: MediaControlProps): JSX.Element => {
    const { valueName, value, label, assets, onValueChange } = props

    const { t } = useTranslation()

    return (
        <Box
            sx={{
                width: '100%',
                mt: 1,
                mb: 2,
                '& .MuiTextField-root': { width: '100%' },
            }}
        >
            <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                    mb: 1,
                }}
            >
                <Typography>{label}</Typography>
            </Grid>
            <AutocompleteNestedAsset
                defaultValue={assets.find((item) => item.id === value.__qbd_fileId)}
                onChange={(_, val) => {
                    onValueChange(valueName, { ...value, __qbd_fileId: val?.item.id ?? '' })
                }}
                inputProps={{ label: valueName }}
            />
            <TextField
                label={t('drawerPanels.dataItemDetailsPanel.attributeAssetValueEdit.altText')}
                value={value.__qbd_altText ?? ''}
                onChange={(event) => onValueChange(valueName, { ...value, __qbd_altText: event.target.value })}
                sx={{ mt: 1 }}
            />
        </Box>
    )
}
