import { Add, ChevronRight, ExpandMore, FiberManualRecord } from '@mui/icons-material'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { TFunction } from 'i18next'
import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

export enum Status {
    PUBLISHED = 'PUBLISHED',
    DEACTIVATED = 'DEACTIVATED',
    NOT_PUBLISHED = 'NOT_PUBLISHED',
}

export interface PageTreeEntry {
    id: string
    name: string
    status: Status
    subpages: PageTreeEntry[]
}

export interface PageTreeProps {
    pages: PageTreeEntry[]
    expanded: string[]
    onSetExpanded: (ids: string[]) => void
    selectedPage: string
    onPageSelect: (id: string) => void
    onPageCreateSubpage?: (entry: PageTreeEntry) => void
}

const recursiveRender = (
    page: PageTreeEntry,
    selectedPage: string,
    onPageCreateSubpage: ((entry: PageTreeEntry) => void) | undefined,
    t: TFunction<'translation', undefined>,
) => {
    const { id, status, name, subpages } = page

    return (
        <TreeItem
            key={id}
            itemId={page.id}
            label={
                <Stack direction={'row'} alignItems={'center'} position={'relative'}>
                    {status === Status.NOT_PUBLISHED && (
                        <FiberManualRecord
                            style={{
                                color: colors.red,
                                height: 8,
                                width: 8,
                                marginRight: 4,
                            }}
                        />
                    )}
                    <Tooltip title={t(`components.pageTree.${status}`)} placement="right">
                        <Typography
                            flex={1}
                            style={{ textDecoration: status === Status.DEACTIVATED ? 'line-through' : 'none' }}
                        >
                            {name}
                        </Typography>
                    </Tooltip>

                    {onPageCreateSubpage && id === selectedPage && (
                        <Tooltip title={t('components.pageTree.createSubpage')}>
                            <IconButton
                                onClick={(event) => {
                                    event.preventDefault()
                                    onPageCreateSubpage(page)
                                }}
                                style={{ position: 'absolute', right: 0, padding: 0, marginLeft: 8 }}
                            >
                                <Add fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            }
            style={{ flex: 1, borderTop: '1px solid #ddd' }}
            ContentProps={{ style: { height: '48px' } }}
        >
            {subpages.map((subpage) => recursiveRender(subpage, selectedPage, onPageCreateSubpage, t))}
        </TreeItem>
    )
}

const PageTree = (props: PageTreeProps): JSX.Element => {
    const { pages, onPageSelect, selectedPage, expanded, onSetExpanded, onPageCreateSubpage } = props

    const { t } = useTranslation()

    const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
        event.persist()
        const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer')
        if (iconClicked) {
            onSetExpanded(nodeIds)
        }
    }

    return (
        <Box style={{ overflowX: 'hidden', overflowY: 'auto' }} height="100%">
            <SimpleTreeView
                slots={{ collapseIcon: ExpandMore, expandIcon: ChevronRight }}
                slotProps={{
                    collapseIcon: { style: { width: 36, height: 36 } },
                    expandIcon: { style: { width: 36, height: 36 } },
                }}
                selectedItems={selectedPage}
                expandedItems={expanded}
                onSelectedItemsChange={(event, itemID) => {
                    event.persist()
                    const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer')
                    if (!iconClicked && itemID) {
                        onPageSelect(itemID)
                    }
                }}
                onExpandedItemsChange={handleToggle}
            >
                {pages.map((page) => recursiveRender(page, selectedPage, onPageCreateSubpage, t))}
            </SimpleTreeView>
        </Box>
    )
}

export default PageTree
