import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { AttributeCommon, DataTypeAttributeType } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { getTypenameFromAttributeType } from 'utils/dataType/functions'
import { AttributeDetailsPanelProps } from './AttributeDetailsPanel'

export type AttributeEditProps = Pick<
    AttributeDetailsPanelProps,
    'selectedAttribute' | 'setSelectedAttribute' | 'errors' | 'selectedItem'
>

export const AttributeCommonEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute, errors } = props

    const { t } = useTranslation()

    const {
        common: { identifier, name, description },
    } = selectedAttribute

    const overrideCommon = (values: Partial<AttributeCommon>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            common: { ...selectedAttribute.common, ...values },
        })

    const errorIdentifier =
        errors.find((error) => error.type === 'innerIdentifierDuplicate') ??
        errors.find((error) => error.type === 'innerIdentifierEmpty')

    const errorName = errors.find((error) => error.type === 'innerNameEmpty')

    return (
        <>
            <FormControl fullWidth variant={'filled'} disabled>
                <InputLabel>{t('drawerPanels.attributeDetailsPanel.attributeCommonEdit.type')}</InputLabel>
                <Select
                    label={t('drawerPanels.attributeDetailsPanel.attributeCommonEdit.type')}
                    value={selectedAttribute.__typename}
                >
                    {Object.values(DataTypeAttributeType)
                        .map(getTypenameFromAttributeType)
                        .map((item) => (
                            <MenuItem key={item} value={item}>
                                {t('common.types.' + item.replace('Attribute', ''))}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeCommonEdit.identifier')}
                value={identifier}
                onChange={(change) => {
                    overrideCommon({ identifier: change.target.value })
                }}
                error={!!errorIdentifier}
                helperText={errorIdentifier?.message ?? ''}
            />
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeCommonEdit.name')}
                value={name}
                onChange={(change) => {
                    overrideCommon({ name: change.target.value })
                }}
                error={!!errorName}
                helperText={errorName?.message ?? ''}
            />
            <TextField
                label={t('drawerPanels.attributeDetailsPanel.attributeCommonEdit.description')}
                value={description}
                onChange={(event) => overrideCommon({ description: event.target.value })}
            />
        </>
    )
}
