import { DataType } from 'graphql/types'
import cloneDeep from 'lodash/cloneDeep'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AttributeUnion } from 'utils/dataType/types'
import { FormError } from 'utils/types'

export interface UseSettingsErrorsReturn {
    errors: FormError[]
    setErrors: (errors: FormError[]) => void
}

export const useSettingsErrors = (
    selectedItem: DataType | undefined,
    dataTypes: DataType[],
    selectedType: DataType | undefined,
    selectedAttribute: AttributeUnion | undefined,
): UseSettingsErrorsReturn => {
    const [errors, setErrors] = useState<FormError[]>([])

    const { t } = useTranslation()

    useLayoutEffect(() => {
        const newErrors: FormError[] = []
        if (!selectedItem) return
        // outer Error handling
        // empty identifier
        if (selectedItem.identifier === '') {
            newErrors.push({ type: 'outerIdentifierEmpty', message: t('common.errors.identifierRequired') })
        } else {
            // not unique identifier
            if (dataTypes.some((item) => item.identifier === selectedItem.identifier)) {
                const match = cloneDeep(dataTypes)
                    .filter((item) => item.identifier === selectedItem.identifier)
                    .filter((item) => item.id !== selectedItem.id)
                if (match.length > 0) {
                    newErrors.push({ type: 'outerIdentifierDuplicate', message: t('common.errors.identifierUnique') })
                }
            }
        }
        // empty name
        if (selectedItem.name === '') {
            newErrors.push({ type: 'outerNameEmpty', message: t('common.errors.nameRequired') })
        }
        setErrors(newErrors)
    }, [selectedType])

    useLayoutEffect(() => {
        const newErrors: FormError[] = []
        if (!selectedItem) return
        if (!selectedAttribute) return

        // Inner Error handling
        // ATTRIBUTE COMMON
        // empty identifier
        if (selectedAttribute.common.identifier === '') {
            newErrors.push({ type: 'innerIdentifierEmpty', message: t('common.errors.identifierRequired') })
        } else {
            // not unique identifier
            if (
                selectedItem.attributeAssetList.length ||
                selectedItem.attributeBooleanList.length ||
                selectedItem.attributeCollectionList.length ||
                selectedItem.attributeLinkList.length ||
                selectedItem.attributeNumberList.length ||
                selectedItem.attributeSelectionList.length ||
                selectedItem.attributeTextList.length ||
                selectedItem.attributeDateList.length
            ) {
                if (
                    selectedItem.attributeAssetList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeBooleanList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeCollectionList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeLinkList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeNumberList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeSelectionList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeTextList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier) ||
                    selectedItem.attributeDateList
                        .filter((item) => item.id !== selectedAttribute.id)
                        .some((item) => item.common.identifier === selectedAttribute.common.identifier)
                ) {
                    newErrors.push({ type: 'innerIdentifierDuplicate', message: t('common.errors.identifierUnique') })
                }
            }
        }
        // empty name
        if (selectedAttribute.common.name === '') {
            newErrors.push({ type: 'innerNameEmpty', message: t('common.errors.nameRequired') })
        }
        // ATTRIBUTE ASSET does not have properties to check
        // ATTRIBUTE BOOLEAN does not have properties to check
        // ATTRIBUTE COLLECTION
        if (selectedAttribute.__typename === 'AttributeCollection') {
            // empty dataType
            if (selectedAttribute.dataType === '' || selectedAttribute.dataType === undefined) {
                newErrors.push({ type: 'innerDataTypeEmpty', message: t('common.errors.subTypeRequired') })
            }
        }
        // ATTRIBUTE LINK does not have properties to check
        // ATTRIBUTE NUMBER
        if (selectedAttribute.__typename === 'AttributeNumber') {
            // min > max
            if (
                selectedAttribute.min !== undefined &&
                selectedAttribute.min !== null &&
                selectedAttribute.max !== undefined &&
                selectedAttribute.max !== null
            ) {
                if (selectedAttribute.min > selectedAttribute.max) {
                    newErrors.push({
                        type: 'innerMinMax',
                        message: t('common.errors.equalOrLessValue'),
                    })
                }
            }
            // less than min
            if (
                selectedAttribute.defaultValue !== undefined &&
                selectedAttribute.defaultValue !== null &&
                selectedAttribute.min !== undefined &&
                selectedAttribute.min !== null
            ) {
                if (selectedAttribute.defaultValue < selectedAttribute.min) {
                    newErrors.push({
                        type: 'innerDefaultValueMin',
                        message: t('common.errors.defaultGreaterThanMin'),
                    })
                }
            }
            // greater than max
            if (
                selectedAttribute.defaultValue !== undefined &&
                selectedAttribute.defaultValue !== null &&
                selectedAttribute.max !== undefined &&
                selectedAttribute.max !== null
            ) {
                if (selectedAttribute.defaultValue > selectedAttribute.max) {
                    newErrors.push({
                        type: 'innerDefaultValueMax',
                        message: t('common.errors.defaultLessThanMax'),
                    })
                }
            }
        }
        // ATTRIBUTE SELECTION
        if (selectedAttribute.__typename === 'AttributeSelection') {
            // empty options
            if (selectedAttribute.options.length === 0) {
                newErrors.push({ type: 'innerEmptyOptions', message: t('common.errors.optionsRequired') })
            } else {
                selectedAttribute.options.forEach((option) => {
                    // empty option name
                    if (option.id !== undefined && option.name === '') {
                        newErrors.push({
                            type: `innerOptionNameEmpty${option.id}`,
                            message: t('common.errors.optionNameRequired'),
                        })
                    }
                    // not unique option name
                    if (selectedAttribute.options.filter((item) => item.name === option.name).length > 1) {
                        newErrors.push({
                            type: `innerOptionNameDuplicate${option.id}`,
                            message: t('common.errors.optionNameUnique'),
                        })
                    }
                })
            }
        }
        // ATTRIBUTE TEXT does not have properties to check
        setErrors(newErrors)
    }, [selectedAttribute as any, selectedItem])

    return { errors, setErrors }
}
