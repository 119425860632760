import { Add, Clear } from '@mui/icons-material'
import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material'
import { UseDataTypeTabsReturn } from 'pages/SettingsPage/DataTypeTab/useDataTypeTab'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

const DataTypePanel = (props: UseDataTypeTabsReturn): JSX.Element => {
    const { dataTypes, onClickCreateType, onClickType, selectedType } = props

    const { t } = useTranslation()

    const [searchTerm, setSearchTerm] = useState('')

    return (
        <List sx={{ marginTop: '-8px' }}>
            <Divider color={colors.midGray} />
            <ListItemButton selected={selectedType?.id === ''} onClick={onClickCreateType}>
                <ListItemIcon>
                    <Add />
                </ListItemIcon>
                <ListItemText primary={t('drawerPanels.dataTypePanel.add')} />
            </ListItemButton>
            <Divider color={colors.midGray} />
            <TextField
                variant="filled"
                size={'small'}
                label={t('drawerPanels.dataTypePanel.search')}
                autoFocus
                fullWidth
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                value={searchTerm}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            disabled={searchTerm === ''}
                            sx={{ opacity: searchTerm === '' ? 0 : 1, marginRight: '-8px', marginLeft: '4px' }}
                            onClick={() => setSearchTerm('')}
                        >
                            <Clear />
                        </IconButton>
                    ),
                }}
            />
            {dataTypes
                .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => (
                    <Fragment key={item.id}>
                        <ListItemButton
                            selected={item.id === selectedType?.id}
                            onClick={() => {
                                onClickType(item)
                            }}
                        >
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                        <Divider color={colors.midGray} />
                    </Fragment>
                ))}
        </List>
    )
}

export default DataTypePanel
