import { Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material'
import { AttributeLink, AttributeLinkAllowedOptions } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeLinkEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute } = props

    const { t } = useTranslation()

    if (selectedAttribute.__typename !== 'AttributeLink') return null

    const { allowed, required } = selectedAttribute

    const override = (values: Partial<AttributeLink>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    return (
        <>
            <Divider />
            <FormControl fullWidth variant={'filled'}>
                <InputLabel>{t('drawerPanels.attributeDetailsPanel.attributeLinkEdit.allowedLinks')}</InputLabel>
                <Select
                    label={t('drawerPanels.attributeDetailsPanel.attributeLinkEdit.allowedLinks')}
                    value={allowed}
                    onChange={(event) => override({ allowed: event.target.value as AttributeLinkAllowedOptions })}
                >
                    {Object.values(AttributeLinkAllowedOptions)
                        .sort((a, b) => a.split('_').length - b.split('_').length) // Sort by number of items
                        .reverse()
                        .map((item) => (
                            <MenuItem key={item} value={item}>
                                {t('drawerPanels.attributeDetailsPanel.attributeLinkEdit.' + item)}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={required ?? false}
                        onChange={(event) => override({ required: event.target.checked })}
                    />
                }
                label={t('drawerPanels.attributeDetailsPanel.attributeLinkEdit.required')}
            />
        </>
    )
}
