import DeleteIcon from '@mui/icons-material/Delete'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { LinkHelper, getParsedURL } from 'components/LinkHelper'
import { Frequency, PageRank, PageSettingInput, PageSettings, RobotsOption } from 'graphql/types'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { checkVersionDaemon } from 'utils/checkVersionDaemon'
import { LanguageVersion, LinkedLanguagePages } from 'utils/types'

export interface PageSettingsEditPanelProps {
    onCancel: () => void
    onSubmit: (pageSettings: PageSettingInput) => void
    openPageLinkSelector: () => void
    removeCurrentLanguageVersion: (id: string) => void
    domain: string
    pagePath: string
    pageSettings: PageSettings
    languageVersion?: LanguageVersion
    saveIsDisabled: boolean
}

const PageSettingsEditPanel = (props: PageSettingsEditPanelProps): JSX.Element => {
    const {
        onCancel,
        onSubmit,
        openPageLinkSelector,
        removeCurrentLanguageVersion,
        pageSettings,
        domain,
        pagePath,
        languageVersion,
        saveIsDisabled,
    } = props

    const { t } = useTranslation()

    //initalise deamon to check version
    checkVersionDaemon()

    const { handleSubmit, control, setValue, watch, clearErrors } = useForm<PageSettingInput>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            ...pageSettings,
            urlTitle: decodeURIComponent(pageSettings.urlTitle),
        },
    })

    // update the values if data changes somehow
    useEffect(() => {
        let key: keyof PageSettings
        for (key in pageSettings) {
            if (key === 'urlTitle') setValue('urlTitle', decodeURIComponent(pageSettings.urlTitle))
            else if (key !== '__typename') setValue(key, pageSettings[key])
        }
    }, [pageSettings])

    const handleOnSave = () => {
        handleSubmit((data: PageSettingInput) => {
            //check if language version exist
            if (languageVersion && languageVersion.linkedLanguagePages.length > 0) {
                //validate if in the language version exist pages with the same language
                //TODO: create custom function
                const usedLangusages: string[] = []
                for (const item of languageVersion.linkedLanguagePages) {
                    if (item.language === data.language || usedLangusages.includes(data.language)) {
                        alert(t('drawerPanels.pageSettingsEditPanel.languageAlert'))
                        return
                    } else {
                        usedLangusages.push(item.language)
                    }
                }
                //set the current language version
                data.translationID = languageVersion.translationID
                data.linkedLanguagePagesIds = languageVersion.linkedLanguagePages.map((item) => item.pageId)
            }
            onSubmit(data)
        })()
    }

    const renderPageVersionEntry = (version: LinkedLanguagePages): JSX.Element | undefined => {
        if (version.pageId !== '') {
            return (
                <ListItem
                    key={version.pageId}
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => removeCurrentLanguageVersion(version.pageId)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                >
                    <ListItemText primary={version.url} secondary={version.language} />
                </ListItem>
            )
        }
    }
    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{t('drawerPanels.pageSettingsEditPanel.settings')}</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="htmlTitle"
                    control={control}
                    rules={{
                        required: t('drawerPanels.pageSettingsEditPanel.nameRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.pageSettingsEditPanel.nameMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('drawerPanels.pageSettingsEditPanel.name')}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message ?? ''}
                            variant={'filled'}
                        />
                    )}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="urlTitle"
                    control={control}
                    rules={{
                        required: t('drawerPanels.pageSettingsEditPanel.urlRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.pageSettingsEditPanel.urlMaxLength') },
                    }}
                    render={({ field, fieldState }) => {
                        return (
                            <>
                                <TextField
                                    label={t('drawerPanels.pageSettingsEditPanel.url')}
                                    fullWidth={true}
                                    {...field}
                                    inputProps={{ maxLength: 150 }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message ?? ''}
                                    variant={'filled'}
                                />
                                <LinkHelper
                                    title={watch().htmlTitle ?? ''}
                                    url={field.value}
                                    setUrl={(url) => {
                                        setValue('urlTitle', url)
                                        clearErrors()
                                    }}
                                />

                                <Link
                                    href={getParsedURL(domain + pagePath + field.value)}
                                    target="_blank"
                                    width={'100%'}
                                    style={{ wordBreak: 'break-all' }}
                                    marginTop={1}
                                >
                                    {getParsedURL(domain + pagePath + field.value)}
                                </Link>
                            </>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="metaDescription"
                    control={control}
                    rules={{
                        maxLength: {
                            value: 150,
                            message: t('drawerPanels.pageSettingsEditPanel.metaDescriptionMaxLength'),
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <TextField
                                multiline
                                minRows={4}
                                maxRows={4}
                                {...field}
                                fullWidth={true}
                                inputProps={{ maxLength: 150 }}
                                label={t('drawerPanels.pageSettingsEditPanel.metaDescription')}
                                variant={'filled'}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="metaKeywords"
                    control={control}
                    rules={{
                        maxLength: {
                            value: 500,
                            message: t('drawerPanels.pageSettingsEditPanel.metaKeywordsMaxLength'),
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <TextField
                                multiline
                                minRows={4}
                                maxRows={4}
                                {...field}
                                fullWidth={true}
                                inputProps={{ maxLength: 500 }}
                                label={t('drawerPanels.pageSettingsEditPanel.metaKeywords')}
                                variant={'filled'}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="robotsOption"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-robots-label">
                                    {t('drawerPanels.pageSettingsEditPanel.informationForSearchEngines')}
                                </InputLabel>
                                <Select
                                    labelId="page-settings-robots-label"
                                    id="page-settings-robots"
                                    label="Information for search engines:"
                                    {...field}
                                >
                                    <MenuItem value={RobotsOption.BOTH}>
                                        {t('drawerPanels.pageSettingsEditPanel.indexPageFollowLinks')}
                                    </MenuItem>
                                    <MenuItem value={RobotsOption.INDEX}>
                                        {t('drawerPanels.pageSettingsEditPanel.indexPageNoFollowLinks')}
                                    </MenuItem>
                                    <MenuItem value={RobotsOption.FOLLOW}>
                                        {t('drawerPanels.pageSettingsEditPanel.noIndexPageFollowLinks')}
                                    </MenuItem>
                                    <MenuItem value={RobotsOption.NONE}>
                                        {t('drawerPanels.pageSettingsEditPanel.noIndexPageNoFollowLinks')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="includeInSitemapXML"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControlLabel
                                control={<Checkbox {...field} />}
                                label={t('drawerPanels.pageSettingsEditPanel.omitInSitemap')}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="changeFrequency"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-change-frequency-label">
                                    {t('drawerPanels.pageSettingsEditPanel.frequencyOfChange')}
                                </InputLabel>
                                <Select
                                    labelId="page-settings-change-frequency-label"
                                    id="page-settings-change-frequency"
                                    label={t('drawerPanels.pageSettingsEditPanel.frequencyOfChange')}
                                    {...field}
                                >
                                    <MenuItem value={Frequency.ALWAYS}>
                                        {t('drawerPanels.pageSettingsEditPanel.always')}
                                    </MenuItem>
                                    <MenuItem value={Frequency.HOURLY}>
                                        {t('drawerPanels.pageSettingsEditPanel.hourly')}
                                    </MenuItem>
                                    <MenuItem value={Frequency.DAILY}>
                                        {t('drawerPanels.pageSettingsEditPanel.daily')}
                                    </MenuItem>
                                    <MenuItem value={Frequency.WEEKLY}>
                                        {t('drawerPanels.pageSettingsEditPanel.weekly')}
                                    </MenuItem>
                                    <MenuItem value={Frequency.MONTHLY}>
                                        {t('drawerPanels.pageSettingsEditPanel.monthly')}
                                    </MenuItem>
                                    <MenuItem value={Frequency.YEARLY}>
                                        {t('drawerPanels.pageSettingsEditPanel.yearly')}
                                    </MenuItem>
                                    <MenuItem value={Frequency.NEVER}>
                                        {t('drawerPanels.pageSettingsEditPanel.never')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="pageRank"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-rank-label">
                                    {t('drawerPanels.pageSettingsEditPanel.importance')}
                                </InputLabel>
                                <Select
                                    labelId="page-settings-rank-label"
                                    id="page-settings-rank"
                                    label={t('drawerPanels.pageSettingsEditPanel.importance')}
                                    {...field}
                                >
                                    <MenuItem value={PageRank.ONE}>0.1</MenuItem>
                                    <MenuItem value={PageRank.TWO}>0.2</MenuItem>
                                    <MenuItem value={PageRank.THREE}>0.3</MenuItem>
                                    <MenuItem value={PageRank.FOUR}>0.4</MenuItem>
                                    <MenuItem value={PageRank.FIVE}>0.5</MenuItem>
                                    <MenuItem value={PageRank.SIX}>0.6</MenuItem>
                                    <MenuItem value={PageRank.SEVEN}>0.7</MenuItem>
                                    <MenuItem value={PageRank.EIGHT}>0.8</MenuItem>
                                    <MenuItem value={PageRank.NINE}>0.9</MenuItem>
                                    <MenuItem value={PageRank.TEN}>1.0</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="language"
                    control={control}
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel id="page-settings-language-label">
                                    {t('drawerPanels.pageSettingsEditPanel.language')}
                                </InputLabel>
                                <Select
                                    labelId="page-settings-language-label"
                                    id="page-settings-language "
                                    label={t('drawerPanels.pageSettingsEditPanel.language')}
                                    {...field}
                                >
                                    <MenuItem value="DE">{t('drawerPanels.pageSettingsEditPanel.german')}</MenuItem>
                                    <MenuItem value="EN">{t('drawerPanels.pageSettingsEditPanel.english')}</MenuItem>
                                    <MenuItem value="ES">{t('drawerPanels.pageSettingsEditPanel.spanish')}</MenuItem>
                                    <MenuItem value="FR">{t('drawerPanels.pageSettingsEditPanel.french')}</MenuItem>
                                    <MenuItem value="IT">{t('drawerPanels.pageSettingsEditPanel.italian')}</MenuItem>
                                    <MenuItem value="FI">{t('drawerPanels.pageSettingsEditPanel.finnish')}</MenuItem>
                                    <MenuItem value="NL">{t('drawerPanels.pageSettingsEditPanel.dutch')}</MenuItem>
                                    <MenuItem value="PT">{t('drawerPanels.pageSettingsEditPanel.portuguese')}</MenuItem>
                                    <MenuItem value="NO">{t('drawerPanels.pageSettingsEditPanel.norwegian')}</MenuItem>
                                    <MenuItem value="RU">{t('drawerPanels.pageSettingsEditPanel.russian')}</MenuItem>
                                    <MenuItem value="SV">{t('drawerPanels.pageSettingsEditPanel.swedish')}</MenuItem>
                                    <MenuItem value="SL">{t('drawerPanels.pageSettingsEditPanel.slovenian')}</MenuItem>
                                    <MenuItem value="HU">{t('drawerPanels.pageSettingsEditPanel.hungarian')}</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Grid>
            <Grid container item direction={'column'} justifyContent={'space-between'} mb={2}>
                <Typography variant="h6">{t('drawerPanels.pageSettingsEditPanel.languageVersions')}</Typography>
                {languageVersion && (
                    <List>
                        {languageVersion.linkedLanguagePages.map(renderPageVersionEntry)}
                        {languageVersion.linkedLanguagePages.length > 1 && <Divider />}
                    </List>
                )}
                <Button variant="contained" color={'secondary'} onClick={openPageLinkSelector}>
                    {t('drawerPanels.pageSettingsEditPanel.selectPage')}
                </Button>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOnSave} disabled={saveIsDisabled}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}

export default PageSettingsEditPanel
