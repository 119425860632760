import { Box, TextField } from '@mui/material'
import { ChangeEvent } from 'react'

export interface TextareaControlProps {
    label: string
    valueName: string
    value: string
    onValueChange: (valueName: string, newValue: string) => void
    multiline?: boolean
    lines?: number
}

export const TextareaControl = (props: TextareaControlProps): JSX.Element => {
    const { valueName, value, onValueChange, label, multiline, lines } = props
    return (
        <Box
            sx={{
                width: '100%',
                mt: 1,
                mb: 2,
                '& .MuiTextField-root': { width: '100%' },
            }}
        >
            <TextField
                id={valueName}
                label={label}
                multiline={multiline}
                value={value}
                minRows={lines}
                variant="filled"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    onValueChange(valueName, e.currentTarget.value)
                }}
            />
        </Box>
    )
}
