export interface KeycloakConfig {
    URL: string
    Realm: string
    ClientID: string
}

export interface EnvConfig {
    HTTPListen: string
    Domain: string
    Keycloak: KeycloakConfig
}

export default function getEnvConfig(): EnvConfig {
    // @ts-expect-error: __envConfig came from backend
    const envConfig: EnvConfig = __envConfig
    return envConfig
}

export const getGraphQLServerURI = (): string => {
    const envConfig = getEnvConfig()
    return process.env.NODE_ENV !== 'production'
        ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/graphql`
        : '/graphql'
}

export const logoutURI = (): string => {
    const envConfig = getEnvConfig()
    return process.env.NODE_ENV !== 'production'
        ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/logout`
        : '/logout'
}
