import { Add, Close, List } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import PageTree, { PageTreeEntry, Status } from 'components/PageTree'
import { Page, State } from 'graphql/types'
import { useTranslation } from 'react-i18next'

export interface PageTreePanelProps {
    pages: Page[]
    expanded: string[]
    onSetExpanded: (ids: string[]) => void
    selectedPage: string
    onSelectPage: (id: string) => void
    switchToSortMode?: () => void
    cancel?: () => void
    onPageCreateRoot?: () => void
    onPageCreateSubpage?: (entry: PageTreeEntry) => void
}

const getStatus = (page: Page): Status => {
    if (page.deactivated) return Status.DEACTIVATED
    else if (page.currentVersion.state === State.UNPUBLISHED) return Status.NOT_PUBLISHED
    else return Status.PUBLISHED
}

const convertPageListToPageTree = (pageList: Page[], currentPage: Page | null): PageTreeEntry[] => {
    const pageTree: PageTreeEntry[] = []

    pageList.forEach((page: Page) => {
        const parentID: string = currentPage ? currentPage.id : ''
        if (page.parentPageIdentifier === parentID) {
            const pageTreeEntry: PageTreeEntry = {
                id: page.id,
                name: page.currentVersion.pageSettings.htmlTitle,
                status: getStatus(page),
                subpages: convertPageListToPageTree(pageList, page),
            }
            pageTree.push(pageTreeEntry)
        }
    })
    return pageTree
}

const PageTreePanel = (props: PageTreePanelProps): JSX.Element => {
    const {
        pages,
        selectedPage,
        expanded,
        onSetExpanded,
        onPageCreateRoot,
        onPageCreateSubpage,
        onSelectPage,
        switchToSortMode,
        cancel,
    } = props

    const { t } = useTranslation()

    return (
        <Box display="flex" flexDirection="column" flex={1} height="100%">
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {cancel && (
                    <Stack justifyContent={'flex-end'} direction={'row'} style={{ width: '100%' }}>
                        <IconButton aria-label="cancel" onClick={cancel}>
                            <Close />
                        </IconButton>
                    </Stack>
                )}
            </Grid>
            <PageTree
                pages={convertPageListToPageTree(pages, null)}
                onPageSelect={onSelectPage}
                selectedPage={selectedPage}
                expanded={expanded}
                onSetExpanded={onSetExpanded}
                onPageCreateSubpage={onPageCreateSubpage}
            />
            <Box marginTop="auto" display="flex" marginLeft="auto">
                {onPageCreateRoot !== undefined && (
                    <Tooltip title={t('drawerPanels.pageTreePanel.createRootPage')}>
                        <IconButton onClick={onPageCreateRoot}>
                            <Add fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
                {switchToSortMode !== undefined && (
                    <Tooltip title={t('drawerPanels.pageTreePanel.reorderPages')}>
                        <IconButton onClick={switchToSortMode}>
                            <List fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}

export default PageTreePanel
