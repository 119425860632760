import { getBackendOptions, MultiBackend, NodeModel, Tree } from '@minoru/react-dnd-treeview'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Page } from 'graphql/types'
import { findIndex } from 'lodash'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import styles from './App.module.css'
import { CustomDragPreview } from './CustomDragPreview'
import { CustomNode } from './CustomNode'
import { Placeholder } from './Placeholder'
import { themeReorderModule } from './theme'
import { CustomData } from './types'

export interface PageTreeOrderProps {
    list: Page[]
    treeData: NodeModel<CustomData>[]
    setTreeData: Dispatch<SetStateAction<NodeModel<CustomData>[]>>
}

const PageTreeReorder = (props: PageTreeOrderProps): JSX.Element => {
    const { list, treeData, setTreeData } = props
    const handleDrop = (newTree: NodeModel<CustomData>[]) => setTreeData(newTree)

    useEffect(() => {
        const sortableItems = list.map((page) => {
            // root folder has index 0
            let parentIndex = 0
            if (page.parentPageIdentifier) {
                const par = findIndex(list, (x) => x.id === page.parentPageIdentifier)
                if (par !== -1) {
                    parentIndex = par + 1 //index starts at 0 so its parentID is atleast 1
                }
            }
            return {
                id: page.sortIndex + 1,
                parent: parentIndex,
                droppable: true,
                text: page.currentVersion.pageSettings.htmlTitle,
                data: {
                    pageID: page.id,
                },
            }
        })
        setTreeData(sortableItems)
    }, [])

    return (
        <ThemeProvider theme={themeReorderModule}>
            <CssBaseline />
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <div className={styles.app}>
                    <Tree
                        tree={treeData}
                        rootId={0}
                        render={(node, { depth, isOpen, onToggle, hasChild }) => (
                            <CustomNode
                                node={node}
                                depth={depth}
                                isOpen={isOpen}
                                onToggle={onToggle}
                                hasChild={hasChild}
                            />
                        )}
                        dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
                        onDrop={handleDrop}
                        classes={{
                            root: styles.treeRoot,
                            draggingSource: styles.draggingSource,
                            placeholder: styles.placeholderContainer,
                        }}
                        sort={false}
                        insertDroppableFirst={false}
                        canDrop={(_, { dragSource, dropTargetId }) => {
                            if (dragSource?.parent === dropTargetId) {
                                return true
                            }
                        }}
                        dropTargetOffset={10}
                        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
                    />
                </div>
            </DndProvider>
        </ThemeProvider>
    )
}

export default PageTreeReorder
