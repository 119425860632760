import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview'
import { Typography } from '@mui/material'
import { FC } from 'react'
import styles from './CustomDragPreview.module.css'
import { CustomData } from './types'

type Props = {
    monitorProps: DragLayerMonitorProps<CustomData>
}

export const CustomDragPreview: FC<Props> = (props) => {
    const item = props.monitorProps.item

    return (
        <div className={styles.root}>
            <Typography variant="body1">{item.text}</Typography>
        </div>
    )
}
