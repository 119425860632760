import { FileUpload } from '@mui/icons-material'
import { Box, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import Dropzone, { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

export interface ImageUploadProps {
    onImageFileChosen: (files: File[]) => void
    onFileReject: (rej: FileRejection[]) => void
}

//TODO: Maybe we need this later
// const acceptUploadType = {
//     'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.webp'],
//     '.doc': [],
//     '.docx': [],
// }

const ImageUpload = (props: ImageUploadProps): JSX.Element => {
    const { onFileReject, onImageFileChosen } = props
    const [dragOver, setDragOver] = useState<boolean>(false)
    const { t } = useTranslation()

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
        setDragOver(false)
        if (fileRejections.length > 0) {
            onFileReject(fileRejections)
        }
        if (acceptedFiles.length > 0) {
            onImageFileChosen(acceptedFiles)
        }
    }

    return (
        // TODO: move to the theme
        <Paper
            style={{
                width: '100%',
                height: 270,
                border: `2px dashed #000`,
                backgroundColor: '#fff',
                display: 'flex',
            }}
        >
            {/* TODO: what is the max size and which file types do we allow? */}
            <Dropzone
                // accept={acceptUploadType}
                maxSize={52428800} //TODO: should come from the server?
                onDragEnter={() => {
                    setDragOver(true)
                }}
                onDragLeave={() => {
                    setDragOver(false)
                }}
                onDrop={onDrop}
            >
                {({ getRootProps, getInputProps }): JSX.Element => (
                    <>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            {...getRootProps()}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <input {...getInputProps()} />

                            <Typography
                                style={{
                                    width: '100%',
                                    padding: '0 15%',
                                    textAlign: 'center',
                                }}
                                variant={'h6'}
                            >
                                <FileUpload />
                                <br />
                                {dragOver
                                    ? t('components.imageUpload.dragging')
                                    : t('components.imageUpload.notDragging')}
                            </Typography>
                        </Box>
                    </>
                )}
            </Dropzone>
        </Paper>
    )
}

export default ImageUpload
