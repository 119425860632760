import { Divider } from '@mui/material'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeDateEdit = (props: AttributeEditProps) => {
    const { selectedAttribute } = props

    if (selectedAttribute.__typename !== 'AttributeDate') return null

    const {} = selectedAttribute

    // TODO: min date, max date, time yes no, weekday etc???

    return (
        <>
            <Divider />
        </>
    )
}
