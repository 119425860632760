import { Box, CircularProgress, Typography } from '@mui/material'
import { default as CMSHeader } from 'components/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerGridProps, DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import PagePreview from 'components/PagePreview'
import PageCreatePanel from 'drawerPanels/PageCreatePanel'
import PageDetailsPanel from 'drawerPanels/PageDetailsPanel'
import PageSettingsEditPanel from 'drawerPanels/PageSettingsEditPanel'
import PageSortPanel from 'drawerPanels/PageSortPanel'
import PageTreePanel from 'drawerPanels/PageTreePanel'
import { CreatePageInput, OrderItemInput, PageSettingInput, PageVersion } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { resolvePageURL } from 'utils/linkResolver'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { getParsedPageElements } from 'utils/parseElementContent'
import { EditingDataType, PageElement } from 'utils/types'

export enum PageTreeViewMode {
    PAGETREE = 'PAGETREE',
    SORT = 'SORT',
    ADD = 'ADD',
    EDIT = 'EDIT',
    PAGE_LINK_SELECTOR = 'PAGE_LINK_SELECTOR',
}

const PageTreePage = (): JSX.Element => {
    //data
    const drawerGridProps: DrawerGridProps = {}

    //hooks and data from store
    const navigate = useNavigate()

    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    // // TODO: think how we can use this computed value without initialising it here
    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const languageVersionList = useStoreState((state) => state.model.languageVersionList)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const currentlySelectedPage = useStoreState((state) => state.model.currentlySelectedPage)
    const pageList = useStoreState((state) => state.model.pageList)
    const expanded = useStoreState((state) => state.model.expanded)
    const blockData = useStoreState((state) => state.model.blockData)
    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    //TODO: move the whole state from the page edit settings into the model
    const currentLanguageVersion = useStoreState((state) => state.model.currentLanguageVersion)
    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)
    const websiteList = useStoreState((state) => state.model.websiteList)

    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)
    const loadPage = useStoreActions((actions) => actions.model.loadPage)
    const deactivatePage = useStoreActions((actions) => actions.model.deactivatePage)
    const deletePage = useStoreActions((actions) => actions.model.deletePage)
    const duplicatePage = useStoreActions((actions) => actions.model.duplicatePage)
    const createPage = useStoreActions((actions) => actions.model.createPage)
    const savePage = useStoreActions((actions) => actions.model.savePage)
    const savePageOrder = useStoreActions((actions) => actions.model.savePageOrder)
    const setExpanded = useStoreActions((actions) => actions.model.setExpanded)
    const updateCurrentLanguageVersion = useStoreActions((actions) => actions.model.updateCurrentLanguageVersion)
    const removeCurrentLanguageVersion = useStoreActions((actions) => actions.model.removeCurrentLanguageVersion)
    const clearCurrentLanguageVersion = useStoreActions((actions) => actions.model.clearCurrentLanguageVersion)
    const setCurrentLanguageVersion = useStoreActions((actions) => actions.model.setCurrentLanguageVersion)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)
    const setCurrentlySelectedPage = useStoreActions((actions) => actions.model.setCurrentlySelectedPage)

    const { t } = useTranslation()

    //internal state
    const [viewMode, setViewMode] = useState<PageTreeViewMode>(PageTreeViewMode.PAGETREE)
    const [expandedPageLinkSelector, setExpandedPageLinkSelector] = useState<string[]>([])
    const [pageElements, setPageElements] = useState<PageElement[]>([])

    // set page elements when we switch page
    useEffect(() => {
        setPageElements(getParsedPageElements(currentlySelectedPage?.currentVersion.contents))
    }, [currentlySelectedPage?.id])

    // set page elements when we click on view for a previous version
    const showPageVersion = (version: PageVersion) => {
        if (currentlySelectedPage) {
            setPageElements(getParsedPageElements(version.contents))
        }
    }

    // add a new page version with the old contents
    const reactivatePageVersion = (version: PageVersion) => {
        if (currentlySelectedPage) {
            savePage({ pageID: currentlySelectedPage.id, content: version.contents })
            setPageElements(getParsedPageElements(version.contents))
        }
    }

    //functions
    const selectPage = (id: string) => {
        if (currentlySelectedPage === undefined || (currentlySelectedPage && currentlySelectedPage.id !== id)) {
            loadPage(id)
        }
    }

    const onDeletePage = (id: string) => {
        if (confirm(t('pages.pageTreePage.confirmDeletePage'))) {
            deletePage(id)
        }
    }

    const onDeactivatePage = (id: string) => {
        deactivatePage(id)
    }

    const onDuplicatePage = (id: string) => {
        duplicatePage({ pageID: id })
    }

    const onSavePage = (pageSettings: PageSettingInput) => {
        if (currentlySelectedPage)
            savePage({
                pageID: currentlySelectedPage.id,
                pageSettings,
            })
        setViewMode(PageTreeViewMode.PAGETREE)
    }

    const onEditPage = () => {
        if (currentlySelectedPage) {
            startEditingPage({
                currentlyEditingData: {
                    id: currentlySelectedPage.id,
                    identifier: '',
                    label: currentlySelectedPage.currentVersion.pageSettings.htmlTitle,
                    contents: currentlySelectedPage.currentVersion.contents,
                    type: EditingDataType.PAGE,
                },
                pageElementContents: getParsedPageElements(currentlySelectedPage.currentVersion.contents),
            })
            navigate(NavigationRoutes.PAGE_EDIT)
        }
    }

    const onPageCreateRoot = () => {
        setCurrentlySelectedPage(undefined)
        setViewMode(PageTreeViewMode.ADD)
    }

    const onPageCreateSubpage = () => {
        setViewMode(PageTreeViewMode.ADD)
    }

    // Loading until user is loaded
    // if the user has a default website, wait for that to be loaded
    if (!loggedInUser || (loggedInUser.defaultWebsiteID !== '' && !selectedWebsite)) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }

    // if we are logged in, but no default website, just dont show the page UI but the header
    if (!selectedWebsite)
        return (
            <Box display={'flex'} height={'100%'}>
                <CMSHeader
                    unpublishedItems={unpublishedItems}
                    selectedWebsite={''}
                    websiteList={websiteList}
                    onSelectWebsite={setSelectedWebsite}
                />
                <Typography margin={'auto'}>{t('pages.pageTreePage.noWebsite')}</Typography>
            </Box>
        )

    if (viewMode === PageTreeViewMode.PAGETREE) {
        // page tree
        drawerGridProps.primary = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreateRoot={onPageCreateRoot}
                onPageCreateSubpage={onPageCreateSubpage}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        if (currentlySelectedPage) {
            drawerGridProps.secondary = (
                <PageDetailsPanel
                    page={currentlySelectedPage}
                    onEditPage={onEditPage}
                    onEditPageSettings={() => {
                        setViewMode(PageTreeViewMode.EDIT)
                        setCurrentLanguageVersion(currentlySelectedPage.id)
                    }}
                    onDeactivate={onDeactivatePage}
                    onDuplicate={onDuplicatePage}
                    onDelete={onDeletePage}
                    showPageVersion={showPageVersion}
                    reactivatePageVersion={reactivatePageVersion}
                />
            )
        }
        // primaryDrawerWidth={DrawerWidth.SMALL}
        // secondaryDrawerWidth={DrawerWidth.MEDIUM}
        drawerGridProps.collapsible = true
    }
    // sort view
    else if (viewMode === PageTreeViewMode.SORT) {
        drawerGridProps.primary = (
            <PageSortPanel
                list={pageList}
                onCancel={() => setViewMode(PageTreeViewMode.PAGETREE)}
                savePageOrder={(elems: OrderItemInput[]) => {
                    savePageOrder(elems)
                    setViewMode(PageTreeViewMode.PAGETREE)
                }}
            />
        )

        drawerGridProps.primaryWidth = DrawerWidth.MEDIUM
    }

    // edit view
    else if (viewMode === PageTreeViewMode.EDIT) {
        drawerGridProps.primary = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreateRoot={onPageCreateRoot}
                onPageCreateSubpage={onPageCreateSubpage}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        if (currentlySelectedPage) {
            drawerGridProps.secondary = (
                <PageSettingsEditPanel
                    saveIsDisabled={savingCurrentVersionDisabled}
                    pageSettings={currentlySelectedPage.currentVersion.pageSettings}
                    onCancel={() => {
                        setViewMode(PageTreeViewMode.PAGETREE)
                        clearCurrentLanguageVersion()
                    }}
                    onSubmit={onSavePage}
                    domain={selectedWebsite.productionURL}
                    pagePath={resolvePageURL(currentlySelectedPage.id, pageList, true)}
                    openPageLinkSelector={() => setViewMode(PageTreeViewMode.PAGE_LINK_SELECTOR)}
                    languageVersion={currentLanguageVersion}
                    removeCurrentLanguageVersion={removeCurrentLanguageVersion}
                />
            )
        }
        drawerGridProps.secondaryWidth = DrawerWidth.MEDIUM
        drawerGridProps.collapsible = false
        drawerGridProps.primaryDisabled = true
    }

    // page link selector view
    else if (viewMode === PageTreeViewMode.PAGE_LINK_SELECTOR) {
        drawerGridProps.primary = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreateRoot={onPageCreateRoot}
                onPageCreateSubpage={onPageCreateSubpage}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        drawerGridProps.secondary = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={(id) => {
                    setViewMode(PageTreeViewMode.EDIT)
                    updateCurrentLanguageVersion(id)
                }}
                cancel={() => setViewMode(PageTreeViewMode.EDIT)}
                selectedPage={''}
                expanded={expandedPageLinkSelector}
                onSetExpanded={setExpandedPageLinkSelector}
            />
        )

        drawerGridProps.secondaryWidth = DrawerWidth.MEDIUM
        drawerGridProps.collapsible = false
        drawerGridProps.primaryDisabled = true
    }

    // add view
    else if (viewMode === PageTreeViewMode.ADD) {
        drawerGridProps.primary = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreateRoot={onPageCreateRoot}
                onPageCreateSubpage={onPageCreateSubpage}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        drawerGridProps.secondary = (
            <PageCreatePanel
                parentPage={currentlySelectedPage}
                domain={selectedWebsite.productionURL}
                pagePath={resolvePageURL(currentlySelectedPage?.id ?? '', pageList) + '/'}
                onCancel={() => setViewMode(PageTreeViewMode.PAGETREE)}
                onSubmit={(input) => {
                    const createPageInput: CreatePageInput = {
                        ...input,
                        websiteID: selectedWebsite.id,
                        parentPageID: currentlySelectedPage?.id,
                    }
                    createPage(createPageInput)
                    setViewMode(PageTreeViewMode.PAGETREE) //TODO: find a better way of closing the panel after creating
                }}
            />
        )

        drawerGridProps.secondaryWidth = DrawerWidth.MEDIUM
        drawerGridProps.collapsible = false
        drawerGridProps.primaryDisabled = true
    }

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid
                {...drawerGridProps}
                collapsible
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        blocks={blockData}
                        disabled={true}
                        pageElements={pageElements}
                        activeBreakpoint={{
                            identifier: 'xl',
                            name: 'xl',
                            editorCssWidth: '100%',
                            fromWidthPixels: 1536,
                        }}
                    />
                }
            />
        </>
    )
}

export default PageTreePage
