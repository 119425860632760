import { TextField } from '@mui/material'
import { AutocompleteNestedAsset } from 'components/AutocompleteNested/AutocompleteNested'
import { AttributeAssetValue } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeAssetValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, setAttributeValue, assets, errors, index } = props

    const { t } = useTranslation()

    if (attributeValue.__typename !== 'AttributeAssetValue') return null
    if (!attribute || attribute.__typename !== 'AttributeAsset') return null

    const { value, alt } = attributeValue

    const override = (values: Partial<AttributeAssetValue>) =>
        setAttributeValue({
            ...attributeValue,
            ...values,
        })

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error = errors.find(
        (error) => error.type === `innerAssetEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`,
    )

    return (
        <>
            <AutocompleteNestedAsset
                defaultValue={assets.find((item) => item.id === value)}
                onChange={(_, value) =>
                    override({ value: value?.item.__typename === 'Asset' ? value?.item?.id : undefined })
                }
                inputProps={{ label: attribute.common.name, error: !!error, helperText: error?.message ?? '' }}
            />
            <TextField
                label={t('drawerPanels.dataItemDetailsPanel.attributeAssetValueEdit.altText')}
                value={alt ?? ''}
                onChange={(event) => override({ alt: event.target.value })}
            />
        </>
    )
}
