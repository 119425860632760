import { Add, Close } from '@mui/icons-material'
import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import PageTree, { PageTreeEntry, Status } from 'components/PageTree'
import { Block, State } from 'graphql/types'
import { useTranslation } from 'react-i18next'

export interface BlockTreePanelProps {
    blocks: Block[]
    selectedBlock: string
    onSelectBlock: (id: string) => void
    cancel?: () => void
    onBlockCreate?: () => void
}

const getStatus = (block: Block): Status => {
    if (block.currentVersion.state === State.UNPUBLISHED) return Status.NOT_PUBLISHED
    else if (block.currentVersion.state === State.PUBLISHED) return Status.PUBLISHED
    else return Status.DEACTIVATED
}

const convertBlockListToPageTree = (blockList: Block[]): PageTreeEntry[] => {
    const pageTree: PageTreeEntry[] = []

    blockList.forEach((block: Block) => {
        const pageTreeEntry: PageTreeEntry = {
            id: block.id,
            name: block.currentVersion.name,
            status: getStatus(block),
            subpages: [],
        }
        pageTree.push(pageTreeEntry)
    })
    return pageTree
}

const BlockTreePanel = (props: BlockTreePanelProps): JSX.Element => {
    const { blocks, selectedBlock, onBlockCreate, onSelectBlock, cancel } = props

    const { t } = useTranslation()

    return (
        <Box display="flex" flexDirection="column" flex={1} height="100%">
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {cancel && (
                    <IconButton aria-label="cancel" onClick={cancel}>
                        <Close />
                    </IconButton>
                )}
            </Grid>
            <PageTree
                pages={convertBlockListToPageTree(blocks)}
                onPageSelect={onSelectBlock}
                selectedPage={selectedBlock}
                expanded={[]}
                onSetExpanded={() => {
                    /*nothing here*/
                }}
            />
            <Box marginTop="auto" display="flex" marginLeft="auto">
                {onBlockCreate !== undefined && (
                    <Tooltip title={t('drawerPanels.blockTreePanel.createBlock')}>
                        <IconButton onClick={onBlockCreate}>
                            <Add fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}

export default BlockTreePanel
