import gql from 'graphql-tag'

export const WEBSITE_SAVE = gql`
    mutation saveWebsite($input: WebsiteInput!) {
        websiteSave(input: $input) {
            id
            name
            previewURL
            productionURL
            elementDefinitions
            createdAt
            updatedAt
            updatedBy {
                id
                name
            }
        }
    }
`
