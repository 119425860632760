import { Close, Delete, DragIndicator } from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { AttributeSelection, AttributeSelectionOption } from 'graphql/types'
import { DragDropContext, Draggable, DraggableProvided, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { AttributeEditProps } from './AttributeCommonEdit'

export const AttributeSelectionEdit = (props: AttributeEditProps) => {
    const { selectedAttribute, setSelectedAttribute, errors } = props

    const { t } = useTranslation()

    if (selectedAttribute.__typename !== 'AttributeSelection') return null

    const { options, defaultValue, required } = selectedAttribute

    const override = (values: Partial<AttributeSelection>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    const onDragEnd: OnDragEndResponder = (result) => {
        const { destination, source } = result
        if (!destination) return
        if (destination.droppableId === source.droppableId && destination.index === source.index) return
        if (destination.droppableId !== source.droppableId) return

        const list = Array.from(options)
        const [removed] = list.splice(source.index, 1)
        list.splice(destination.index, 0, removed)

        override({ options: list })
    }

    const onClickAddOption = () => {
        override({ options: [...options, { id: v4(), name: '', description: '' }] })
    }

    const onClickDeleteOption = (option: AttributeSelectionOption) => {
        override({
            defaultValue:
                options.filter((item) => item.id !== option.id).find((item) => item.id === defaultValue) === undefined
                    ? undefined
                    : defaultValue,
            options: options.filter((item) => item.id !== option.id),
        })
    }

    const onChangeItem = (item: AttributeSelectionOption) =>
        override({ options: options.map((old) => (old.id === item.id ? item : old)) })

    const errorDefault =
        errors.find((error) => error.type === 'innerEmptyOptions') ??
        errors.find((error) => error.type.startsWith('innerOptionNameEmpty'))

    return (
        <>
            <Divider />
            <FormControl fullWidth variant={'outlined'}>
                <InputLabel>
                    {t('drawerPanels.attributeDetailsPanel.attributeSelectionEdit.defaultSelection')}
                </InputLabel>
                <Select
                    label={t('drawerPanels.attributeDetailsPanel.attributeSelectionEdit.defaultSelection')}
                    value={defaultValue ?? ''}
                    onChange={(event) => override({ defaultValue: event.target.value })}
                    error={!!errorDefault}
                    endAdornment={
                        defaultValue !== undefined && defaultValue !== '' && defaultValue !== null ? (
                            <IconButton
                                size={'small'}
                                sx={{ position: 'absolute', right: 36 }}
                                onClick={() => override({ defaultValue: '' })}
                            >
                                <Close />
                            </IconButton>
                        ) : undefined
                    }
                >
                    {options.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>{errorDefault?.message ?? ''}</FormHelperText>
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={required ?? false}
                        onChange={(event) => override({ required: event.target.checked })}
                    />
                }
                label={t('drawerPanels.attributeDetailsPanel.attributeSelectionEdit.required')}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Droppable droppableId={'options'}>
                        {(provided) => (
                            <span {...provided.droppableProps} ref={provided.innerRef}>
                                {options.map((item, index) => {
                                    const errorOption =
                                        errors.find((error) => error.type === `innerOptionNameEmpty${item.id}`) ??
                                        errors.find((error) => error.type === `innerOptionNameDuplicate${item.id}`)

                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided: DraggableProvided) => (
                                                <Card
                                                    key={item.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    component={Box}
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    padding={'8px'}
                                                    marginBottom={'8px'}
                                                    alignItems={'center'}
                                                    position={'relative'}
                                                    sx={{
                                                        ':hover': {
                                                            '> button': {
                                                                display: 'flex',
                                                            },
                                                        },
                                                        '> button': {
                                                            display: 'none',
                                                        },
                                                    }}
                                                >
                                                    <DragIndicator fontSize="large" />
                                                    <Box
                                                        display="flex"
                                                        flexDirection={'column'}
                                                        flex={1}
                                                        gap={'8px'}
                                                        marginLeft={'8px'}
                                                    >
                                                        <TextField
                                                            label={t(
                                                                'drawerPanels.attributeDetailsPanel.attributeSelectionEdit.name',
                                                            )}
                                                            value={item.name}
                                                            variant={'standard'}
                                                            onChange={(event) => {
                                                                onChangeItem({ ...item, name: event.target.value })
                                                            }}
                                                            error={!!errorOption}
                                                            helperText={errorOption?.message ?? ''}
                                                        />
                                                        <TextField
                                                            label={t(
                                                                'drawerPanels.attributeDetailsPanel.attributeSelectionEdit.description',
                                                            )}
                                                            value={item.description}
                                                            variant={'standard'}
                                                            onChange={(event) =>
                                                                onChangeItem({
                                                                    ...item,
                                                                    description: event.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Box>
                                                    <Tooltip title={t('drawerPanels.attributeDetailsPanel.remove')}>
                                                        <IconButton
                                                            sx={{
                                                                position: 'absolute',
                                                                bottom: 6,
                                                                left: 6,
                                                                color: '#d32f2f',
                                                            }}
                                                            onClick={() => onClickDeleteOption(item)}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Card>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </span>
                        )}
                    </Droppable>

                    {options.length === 0 && (
                        <Typography marginBottom={'8px'}>
                            {t('drawerPanels.attributeDetailsPanel.attributeSelectionEdit.noOptions')}
                        </Typography>
                    )}

                    <Button variant={'contained'} fullWidth onClick={onClickAddOption}>
                        {t('drawerPanels.attributeDetailsPanel.attributeSelectionEdit.add')}
                    </Button>
                </Box>
            </DragDropContext>
        </>
    )
}
