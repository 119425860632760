import { NodeModel } from '@minoru/react-dnd-treeview'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import { FC, MouseEvent } from 'react'
import styles from './CustomNode.module.css'
import { CustomData } from './types'

type Props = {
    node: NodeModel<CustomData>
    depth: number
    isOpen: boolean
    onToggle: (id: NodeModel['id']) => void
    hasChild: boolean
}

export const CustomNode: FC<Props> = (props) => {
    const { node, hasChild, depth, onToggle, isOpen } = props
    const indent = depth * 24

    const handleToggle = (e: MouseEvent) => {
        e.stopPropagation()
        onToggle(node.id)
    }

    return (
        <div className={`tree-node ${styles.root}`} style={{ paddingInlineStart: indent }}>
            <div className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ''}`}>
                {hasChild && (
                    <div onClick={handleToggle}>
                        <ChevronRightIcon fontSize="large" />
                    </div>
                )}
            </div>
            <div className={styles.labelGridItem}>
                <Typography variant="body1">{`${node.text}`}</Typography>
            </div>
        </div>
    )
}
