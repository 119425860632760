import { Clear } from '@mui/icons-material'
import { Divider, IconButton, List, ListItemButton, ListItemText, TextField } from '@mui/material'
import { UseDataItemPageReturn } from 'pages/DataItemPage/UseDataItemPage'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'

const DataItemPanel = (props: UseDataItemPageReturn): JSX.Element => {
    const { dataTypes, onClickType, selectedType } = props
    const [searchTerm, setSearchTerm] = useState('')
    const { t } = useTranslation()

    return (
        <List>
            <TextField
                variant="filled"
                size={'small'}
                label={t('drawerPanels.dataItemPanel.search')}
                autoFocus
                fullWidth
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                value={searchTerm}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            disabled={searchTerm === ''}
                            sx={{ opacity: searchTerm === '' ? 0 : 1, marginRight: '-8px', marginLeft: '4px' }}
                            onClick={() => setSearchTerm('')}
                        >
                            <Clear />
                        </IconButton>
                    ),
                }}
            />
            {dataTypes
                .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => (
                    <Fragment key={item.id}>
                        <ListItemButton selected={item.id === selectedType?.id} onClick={() => onClickType(item)}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                        <Divider color={colors.midGray} />
                    </Fragment>
                ))}
        </List>
    )
}

export default DataItemPanel
