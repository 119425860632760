import { NodeModel } from '@minoru/react-dnd-treeview'
import { Box, Button, Stack, Typography } from '@mui/material'
import { CustomData } from 'components/PageTreeReorder//types'
import PageTreeReorder from 'components/PageTreeReorder/PageTreeReorder'
import { OrderItemInput, Page } from 'graphql/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface PageSortPanelProps {
    list: Page[]
    savePageOrder: (elems: OrderItemInput[]) => void
    onCancel: () => void
}

const PageSortPanel = (props: PageSortPanelProps): JSX.Element => {
    const { savePageOrder, onCancel, list } = props

    const { t } = useTranslation()

    const [treeData, setTreeData] = useState<NodeModel<CustomData>[]>([])

    const handleSave = () => {
        const finalOrder: OrderItemInput[] = []
        const recursiveTree = (parentRef: NodeModel<CustomData>, list: NodeModel<CustomData>[]) => {
            list.forEach((elem) => {
                if (elem.parent === parentRef.id) {
                    const data = elem.data
                    const parentData = parentRef.data
                    if (data && parentData) {
                        finalOrder.push({ id: data.pageID, parent: parentData.pageID })
                    }
                    recursiveTree(elem, list)
                }
            })
        }

        for (const treeEntry of treeData) {
            if (treeEntry.parent === 0) {
                const data = treeEntry.data
                if (data) {
                    finalOrder.push({ id: data.pageID })
                    recursiveTree(treeEntry, treeData)
                }
            }
        }

        savePageOrder(finalOrder)
    }

    return (
        <Stack direction="column" position={'relative'}>
            <Stack
                direction={'column'}
                boxShadow={'0px 1px 0px 0px rgba(0,0,0,1)'}
                position={'sticky'}
                top={0}
                zIndex={20}
                paddingX={2}
                bgcolor={'#fff'}
                paddingTop={2}
            >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={'16px'}>
                    <Button variant="contained" color={'secondary'} fullWidth onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" fullWidth onClick={handleSave}>
                        {t('common.save')}
                    </Button>
                </Stack>
                <Typography variant="h6" marginY={1}>
                    {t('drawerPanels.pageSortPanel.reorderPages')}
                </Typography>
            </Stack>

            <Box flex={1} overflow={'auto'}>
                <PageTreeReorder list={list} treeData={treeData} setTreeData={setTreeData} />
            </Box>
        </Stack>
    )
}

export default PageSortPanel
