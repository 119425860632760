import { Box, Button, Grid, Link, Stack, TextField, Typography } from '@mui/material'
import { LinkHelper, getParsedURL } from 'components/LinkHelper'
import { CreatePageInput, Page } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type PageCreateForm = Omit<CreatePageInput, 'parentPageID' | 'websiteID'>
export interface PageCreatePanelProps {
    domain: string
    pagePath: string
    parentPage?: Page
    onCancel: () => void
    onSubmit: (input: PageCreateForm) => void
}

const PageCreatePanel = (props: PageCreatePanelProps): JSX.Element => {
    const { onCancel, parentPage, onSubmit, domain, pagePath } = props

    const { t } = useTranslation()

    const { handleSubmit, control, setValue, watch, clearErrors } = useForm<PageCreateForm>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            title: '',
            url: '',
        },
    })

    const handleOnSave = () => handleSubmit(onSubmit)()

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Typography variant="h6">{t('drawerPanels.pageCreatePanel.newPage')}</Typography>
            <Typography marginBottom={2}>
                {parentPage
                    ? `${t('drawerPanels.pageCreatePanel.subpageOf')}"'${
                          parentPage.currentVersion.pageSettings.htmlTitle
                      }"`
                    : t('drawerPanels.pageCreatePanel.root')}
            </Typography>
            <Stack direction="row" spacing={2} marginBottom={2}>
                <Button variant="contained" fullWidth color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" fullWidth onClick={handleOnSave}>
                    {t('common.save')}
                </Button>
            </Stack>
            <Grid container mb={2}>
                <Controller
                    name="title"
                    control={control}
                    rules={{
                        required: t('drawerPanels.pageCreatePanel.nameRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.pageCreatePanel.nameMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('drawerPanels.pageCreatePanel.name')}
                            inputProps={{ maxLength: 150 }}
                            fullWidth={true}
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message ?? ''}
                            variant={'filled'}
                        />
                    )}
                />
            </Grid>
            <Grid container item mb={2}>
                <Controller
                    name="url"
                    control={control}
                    rules={{
                        required: t('drawerPanels.pageCreatePanel.urlRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.pageCreatePanel.urlMaxLength') },
                    }}
                    render={({ field, fieldState }) => {
                        return (
                            <>
                                <TextField
                                    label={t('drawerPanels.pageCreatePanel.url')}
                                    fullWidth={true}
                                    {...field}
                                    inputProps={{ maxLength: 150 }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message ?? ''}
                                    variant={'filled'}
                                />
                                <LinkHelper
                                    title={watch().title ?? ''}
                                    url={field.value}
                                    setUrl={(url) => {
                                        setValue('url', url)
                                        clearErrors()
                                    }}
                                />

                                <Link
                                    href={getParsedURL(domain + pagePath + field.value)}
                                    target="_blank"
                                    width={'100%'}
                                    style={{ wordBreak: 'break-all' }}
                                    marginTop={1}
                                >
                                    {getParsedURL(domain + pagePath + field.value)}
                                </Link>
                            </>
                        )
                    }}
                />
            </Grid>
        </Box>
    )
}

export default PageCreatePanel
