import { Clear } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material'
import { cloneDeep } from 'lodash'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'store/hooks'
import colors from 'utils/colors'

export interface AddElementPanelProps {
    displayBlockOptions: boolean
    closeAddDrawer: () => void
}

enum ElementType {
    PAGE,
    BLOCK,
    CLIPBOARD,
}

const AddElementPanel = (props: AddElementPanelProps): JSX.Element => {
    const { closeAddDrawer, displayBlockOptions } = props

    const confirmAddElement = useStoreActions((actions) => actions.model.confirmAddElement)
    const removeFromClipboard = useStoreActions((actions) => actions.model.removeFromClipboard)
    const pageContentClipboard = useStoreState((state) => state.model.pageContentClipboard)
    const blockList = useStoreState((state) => state.model.blockList)
    const elementDefinitions = useStoreState((state) => state.model.elementDefinitionList)

    const [elementType, setElementType] = useState<ElementType>(ElementType.PAGE)

    const handleElementTypeChange = (event: MouseEvent<HTMLElement>, elementType: ElementType) => {
        setElementType(elementType)
    }

    const [searchTerm, setSearchTerm] = useState('')

    const { t } = useTranslation()

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>
            <Stack
                direction={'row'}
                sx={{ paddingX: '16px', paddingBottom: '8px' }}
                justifyContent="space-between"
                alignItems={'center'}
                boxShadow={'0px 0px 2px 0px rgba(0,0,0,1)'}
            >
                <Typography variant="h6">{t('drawerPanels.addElementPanel.add')}</Typography>
                <ToggleButtonGroup color="primary" value={elementType} exclusive onChange={handleElementTypeChange}>
                    <ToggleButton value={ElementType.PAGE}>{t('drawerPanels.addElementPanel.element')}</ToggleButton>
                    {displayBlockOptions && (
                        <ToggleButton value={ElementType.BLOCK}>{t('drawerPanels.addElementPanel.block')}</ToggleButton>
                    )}
                    <Tooltip title={t('drawerPanels.addElementPanel.clipboardTooltip')} placement="right">
                        <ToggleButton value={ElementType.CLIPBOARD}>
                            {t('drawerPanels.addElementPanel.clipboard')}
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </Stack>

            {elementType === ElementType.PAGE && (
                <TextField
                    variant="filled"
                    size={'small'}
                    label={t('drawerPanels.addElementPanel.search')}
                    autoFocus
                    fullWidth
                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                    value={searchTerm}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                disabled={searchTerm === ''}
                                sx={{
                                    opacity: searchTerm === '' ? 0 : 1,
                                    marginRight: '-8px',
                                    marginLeft: '4px',
                                }}
                                onClick={() => setSearchTerm('')}
                            >
                                <Clear />
                            </IconButton>
                        ),
                    }}
                />
            )}

            <List sx={{ flex: 1, overflowY: 'auto', paddingTop: 0 }}>
                {elementType === ElementType.PAGE &&
                    elementDefinitions
                        .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map((element) => (
                            <>
                                <ListItemButton
                                    key={element.identifier}
                                    onClick={() => {
                                        confirmAddElement({
                                            type: element.identifier,
                                            initialData: cloneDeep(element.initialData),
                                        })
                                    }}
                                >
                                    <ListItemText primary={element.name} />
                                </ListItemButton>
                                <Divider color={colors.midGray} />
                            </>
                        ))}

                {elementType === ElementType.BLOCK &&
                    displayBlockOptions &&
                    blockList.map((block) => (
                        <>
                            <ListItemButton
                                key={block.id}
                                onClick={() => {
                                    confirmAddElement({ type: '__qbd_block', initialData: { __qbd_blockId: block.id } })
                                }}
                            >
                                <ListItemText primary={block.currentVersion.name} />
                            </ListItemButton>
                            <Divider color={colors.midGray} />
                        </>
                    ))}
                {elementType === ElementType.CLIPBOARD &&
                    pageContentClipboard.reverse().map((element) => (
                        <>
                            <ListItemButton
                                key={element.id}
                                onClick={() => {
                                    confirmAddElement({ type: element.type, initialData: element.data })
                                }}
                            >
                                <ListItemText primary={element.type} />
                                <ListItemSecondaryAction>
                                    <Tooltip
                                        title={t('drawerPanels.addElementPanel.removeFromClipboard')}
                                        placement={'right'}
                                    >
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                removeFromClipboard(element)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider color={colors.midGray} />
                        </>
                    ))}
            </List>
            <Box display={'flex'} justifyContent={'flex-end'} margin={'16px'}>
                <Button variant="contained" onClick={closeAddDrawer}>
                    {t('common.cancel')}
                </Button>
            </Box>
        </Box>
    )
}

export default AddElementPanel
