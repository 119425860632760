import { Button, Stack } from '@mui/material'
import { DataType } from 'graphql/types'
import { UseDataTypeTabsReturn } from 'pages/SettingsPage/DataTypeTab/useDataTypeTab'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { AttributeUnion } from 'utils/dataType/types'
import { AttributeAssetEdit } from './AttributeAssetEdit'
import { AttributeBooleanEdit } from './AttributeBooleanEdit'
import { AttributeCollectionEdit } from './AttributeCollectionEdit'
import { AttributeCommonEdit } from './AttributeCommonEdit'
import { AttributeDateEdit } from './AttributeDateEdit'
import { AttributeLinkEdit } from './AttributeLinkEdit'
import { AttributeNumberEdit } from './AttributeNumberEdit'
import { AttributeSelectionEdit } from './AttributeSelectionEdit'
import { AttributeTextEdit } from './AttributeTextEdit'

export type AttributeDetailsPanelProps = Omit<UseDataTypeTabsReturn, 'selectedItem' | 'selectedAttribute'> & {
    selectedItem: DataType
    selectedAttribute: AttributeUnion
}

const AttributeDetailsPanel = (props: AttributeDetailsPanelProps): JSX.Element | null => {
    const { onClickCancelAttribute, onClickSaveAttribute, errors } = props
    const { t } = useTranslation()

    return (
        <Stack direction="column" p={2} position={'relative'} gap={2}>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={'16px'}
                position={'sticky'}
                top={0}
                zIndex={20}
                bgcolor={colors.lightGray}
                padding={2}
                margin={-2}
            >
                <Button variant="contained" color={'secondary'} fullWidth onClick={onClickCancelAttribute}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" fullWidth onClick={onClickSaveAttribute} disabled={errors.length > 0}>
                    {t('common.save')}
                </Button>
            </Stack>

            {/* Render specific properties, actual check is done in components */}
            <AttributeCommonEdit {...props} />
            <AttributeTextEdit {...props} />
            <AttributeNumberEdit {...props} />
            <AttributeBooleanEdit {...props} />
            <AttributeLinkEdit {...props} />
            <AttributeAssetEdit {...props} />
            <AttributeCollectionEdit {...props} />
            <AttributeSelectionEdit {...props} />
            <AttributeDateEdit {...props} />
        </Stack>
    )
}

export default AttributeDetailsPanel
