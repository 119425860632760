import { Divider, Typography } from '@mui/material'
import { AttributeAssetValueEdit } from './AttributeAssetValueEdit'
import { AttributeBooleanValueEdit } from './AttributeBooleanValueEdit'
import { AttributeCollectionValueEdit } from './AttributeCollectionValueEdit'
import { AttributeDateValueEdit } from './AttributeDateValueEdit'
import { AttributeLinkValueEdit } from './AttributeLinkValueEdit'
import { AttributeNumberValueEdit } from './AttributeNumberValueEdit'
import { AttributeSelectionValueEdit } from './AttributeSelectionValueEdit'
import { AttributeTextValueEdit } from './AttributeTextValueEdit'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, hideDivider } = props

    const showName = attribute?.__typename === 'AttributeCollection'

    return (
        <>
            {!hideDivider && <Divider />}
            {showName && <Typography variant="h6">{attribute.common.name}</Typography>}
            {attribute?.common.description && <Typography>{attribute.common.description}</Typography>}
            <AttributeAssetValueEdit {...props} />
            <AttributeBooleanValueEdit {...props} />
            <AttributeCollectionValueEdit {...props} />
            <AttributeLinkValueEdit {...props} />
            <AttributeNumberValueEdit {...props} />
            <AttributeSelectionValueEdit {...props} />
            <AttributeTextValueEdit {...props} />
            <AttributeDateValueEdit {...props} />
        </>
    )
}
