import { Check, Close, Delete } from '@mui/icons-material'
import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid'
import { AssetType, AttributeSelection } from 'graphql/types'
import _ from 'lodash'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataItemUnion, LinkOption, getLinkOptionFromValue } from 'utils/dataType/types'
import { formatDateNumberToDistanceToNow, formatDateNumberToString } from 'utils/functions/functions'
import { resolvePageURL } from 'utils/linkResolver'
import { DataItemEditPanelProps } from './DataItemEditPanel'

export interface UseDataItemEditPanelReturn {
    filteredItems: DataItemUnion[]
    searchTerm: string
    setSearchTerm: (value: string) => void
    apiRef?: any
    tableHeaders: GridColDef[]
}

interface HeaderItem {
    field: string
    headerName: string
    width: number | boolean
    type: HeaderItemType
}

type HeaderItemType = 'text' | 'number' | 'boolean' | 'asset' | 'link' | 'selection' | 'collection' | 'date'

export const useDataItemEditPanel = (props: DataItemEditPanelProps): UseDataItemEditPanelReturn => {
    const { attributes, assets, pages, onTogglePublishItem, onClickDeleteItem, items } = props

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [tableHeaders, setTableHeaders] = useState<GridColDef[]>([])
    const apiRef = useGridApiRef()

    const { t } = useTranslation()

    useLayoutEffect(() => {
        if (attributes && attributes.length > 0 && assets && pages) {
            const headers: HeaderItem[] = []
            // Generate headers from attributes
            attributes.forEach((item, index) => {
                // only push items that are visible, but dont skip indexes
                if (item.common.visible)
                    headers.push({
                        field: `values.${index}.value`,
                        headerName: item.common.name,
                        width: true,
                        type: (item.__typename?.replace('Attribute', '')?.toLowerCase() as HeaderItemType) ?? 'text',
                    })
            })
            // Add updated field
            headers.push({
                field: `updated.timestamp`,
                headerName: t('drawerPanels.dataItemEditPanel.updated'),
                width: true,
                type: 'date',
            })

            const newTableHeaders: GridColDef[] = []
            // Add published column
            newTableHeaders.push({
                field: 'published',
                headerName: t('drawerPanels.dataItemEditPanel.published'),
                flex: 0.5,
                minWidth: 50,
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Box display={'flex'} alignItems={'center'} flex={1} justifyContent={'flex-start'}>
                            <Checkbox
                                checked={params.row.published}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onTogglePublishItem(params.row)
                                }}
                            />
                        </Box>
                    )
                },
            })
            // Generate rows from headers
            for (const header of headers) {
                newTableHeaders.push({
                    field: header.field,
                    headerName: header.headerName,
                    flex:
                        typeof header.width === 'boolean'
                            ? 1
                            : header.width === -1
                              ? 0
                              : header.width
                                ? header.width / 12
                                : undefined,
                    align: 'left',
                    renderCell: (params) => {
                        const value = _.get(params.row, header.field)

                        if (header.type === 'link') {
                            const id = value as string
                            const type = getLinkOptionFromValue(id)

                            if (type === LinkOption.INTERNAL) {
                                const option = pages.find((page) => page.id === id)?.currentVersion.pageSettings
                                const resolvedURL = resolvePageURL(id, pages, false)

                                return (
                                    <Tooltip title={resolvedURL} placement={'top-start'}>
                                        <Typography flex={1}>{option ? option.htmlTitle : value}</Typography>
                                    </Tooltip>
                                )
                            } else if (type === LinkOption.EXTERNAL) {
                                return (
                                    <Tooltip title={value as string} placement={'top-start'}>
                                        <Typography flex={1}>{value as string}</Typography>
                                    </Tooltip>
                                )
                            } else {
                                const option = assets.find((asset) => asset.id === id)
                                let thumbnailURL = '/img/file.png'
                                if (option?.type === AssetType.IMAGE && option?.url) thumbnailURL = option?.url
                                else if (option?.type === AssetType.PDF) thumbnailURL = '/img/pdf.png'
                                return (
                                    <Tooltip title={option?.name} placement={'top-start'}>
                                        <img
                                            src={thumbnailURL ? thumbnailURL : value}
                                            style={{
                                                maxWidth: '60px',
                                                maxHeight: '60px',
                                            }}
                                        />
                                    </Tooltip>
                                )
                            }
                        }
                        if (header.type === 'asset') {
                            const id = value as string
                            const option = assets.find((asset) => asset.id === id)
                            let thumbnailURL = '/img/file.png'
                            if (option?.type === AssetType.IMAGE && option?.url) thumbnailURL = option?.url
                            else if (option?.type === AssetType.PDF) thumbnailURL = '/img/pdf.png'
                            return (
                                <Tooltip title={option?.name} placement={'top-start'}>
                                    <img
                                        src={thumbnailURL ? thumbnailURL : value}
                                        style={{
                                            maxWidth: '60px',
                                            maxHeight: '60px',
                                        }}
                                    />
                                </Tooltip>
                            )
                        }
                        if (header.type === 'selection') {
                            const id: string = value
                            const optionName = (
                                attributes.find(
                                    (attribute) => attribute.__typename === 'AttributeSelection',
                                ) as AttributeSelection
                            ).options.find((option) => option.id === id)?.name
                            return (
                                <Tooltip title={optionName} placement={'top-start'}>
                                    <Typography flex={1}>{optionName ? optionName : value}</Typography>
                                </Tooltip>
                            )
                        }
                        if (header.type === 'boolean') {
                            if (params.row[header.field] === undefined) {
                                const bool = value as boolean
                                return (
                                    <Tooltip title={bool.toString()} placement={'top-start'}>
                                        {value === true ? <Check /> : <Close />}
                                    </Tooltip>
                                )
                            }
                        }
                        if (header.type === 'date') {
                            return (
                                <Tooltip
                                    placement={'top-start'}
                                    title={`${formatDateNumberToString(
                                        value as unknown as number,
                                        'en',
                                    )} - ${formatDateNumberToDistanceToNow(value as unknown as number, 'en', true)}`}
                                >
                                    <Box display={'flex'} flexDirection={'column'}>
                                        <Typography>
                                            {formatDateNumberToString(value as unknown as number, 'en')}
                                        </Typography>
                                        <Typography>
                                            {formatDateNumberToDistanceToNow(value as unknown as number, 'en', true)}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            )
                        }
                        if (header.type === 'collection') {
                            return (
                                <Tooltip title={value.length + ' items'} placement={'top-start'}>
                                    <Typography flex={1}>{value.length}</Typography>
                                </Tooltip>
                            )
                        }
                        if (params.row[header.field] === null || params.row[header.field] === undefined) {
                            return (
                                <Tooltip title={value as unknown as string} placement={'top-start'}>
                                    <Typography flex={1}>{value as unknown as string}</Typography>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <Tooltip title={params.row[header.field] as unknown as string} placement={'top-start'}>
                                    <Typography flex={1}>{params.row[header.field] as unknown as string}</Typography>
                                </Tooltip>
                            )
                        }
                    },
                })
            }
            newTableHeaders.push({
                field: ' ',
                headerName: '',
                flex: 0.5,
                minWidth: 50,
                sortable: false,
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Tooltip arrow title={'Delete item'}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onClickDeleteItem(params.row)
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )
                },
            })
            setTableHeaders(newTableHeaders)
        }
    }, [items, attributes, onClickDeleteItem, onTogglePublishItem, assets, pages, setTableHeaders])

    // Set default sorting
    useLayoutEffect(() => {
        const sortModel = apiRef.current.getSortModel()
        if (tableHeaders.length > 0 && sortModel.length === 0) {
            apiRef.current.setSortModel([
                {
                    field: 'updated.timestamp',
                    sort: 'desc',
                },
            ])
        }
    }, [apiRef, tableHeaders])

    const filteredItems = items.filter((item) => {
        const search = searchTerm.toLowerCase()

        // first convert all values to searchable strings
        const values = item.values
            .map((value) => {
                if (
                    value.__typename === 'AttributeTextValue' ||
                    value.__typename === 'AttributeNumberValue' ||
                    value.__typename === 'AttributeCollectionValue' ||
                    value.__typename === 'AttributeBooleanValue'
                ) {
                    return value.value.toString().toLowerCase()
                } else if (value.__typename === 'AttributeSelectionValue') {
                    const attribute = attributes.find(
                        (attribute) => attribute.__typename === 'AttributeSelection',
                    ) as AttributeSelection
                    const option = attribute.options.find((option) => option.id === value.value)
                    return option?.name?.toLowerCase()
                } else if (value.__typename === 'AttributeLinkValue') {
                    const linkOption = getLinkOptionFromValue(value.value)

                    if (linkOption === LinkOption.INTERNAL) {
                        const option = pages.find((page) => page.id === value.value)?.currentVersion.pageSettings
                            .htmlTitle
                        return option?.toLowerCase()
                    } else if (linkOption === LinkOption.EXTERNAL) {
                        return value.value.toLowerCase()
                    } else {
                        const option = assets.find((asset) => asset.id === value.value)
                        return option?.name?.toLowerCase()
                    }
                } else if (value.__typename === 'AttributeAssetValue') {
                    const asset = assets.find((asset) => asset.id === value.value)
                    return asset?.name?.toLowerCase()
                } else if (value.__typename === 'AttributeDateValue') {
                    return formatDateNumberToString(value.value, 'en')
                }
            })
            .filter((value) => value !== undefined)

        // then filter id and values by search string
        // FIXME: For some unholy reason we cannot return all items here directly or the page will break lol
        return values.some((value) => value?.includes(search)) || (search !== '' && item.id.includes(search))
    })

    return {
        searchTerm,
        setSearchTerm,
        apiRef,
        tableHeaders,
        filteredItems,
    }
}
