import { Delete, DragIndicator, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { DataTypeCategory } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { AttributeUnion } from 'utils/dataType/types'
import { DataTypeEditPanelProps } from './DataTypeEditPanel'

export type DataTypeAttributeRowProps = DataTypeEditPanelProps & { item: AttributeUnion }

export const DataTypeAttributeRow = (props: DataTypeAttributeRowProps) => {
    const {
        selectedAttribute,
        item,
        onClickAttribute,
        onClickDeleteAttribute,
        onClickToggleVisibilityAttribute,
        category,
    } = props

    const { t } = useTranslation()

    return (
        <Box
            display="flex"
            flexDirection="row"
            flex={1}
            gap={1}
            alignItems={'center'}
            onClick={() => {
                onClickAttribute(item)
            }}
            paddingX={3}
            paddingY={1}
            sx={{
                cursor: !selectedAttribute ? 'pointer' : 'grab',
                ':hover': { bgcolor: colors.primaryHalfOpacity },
                backgroundColor: selectedAttribute?.id === item.id ? colors.primary : colors.whiteHalfOpacity,
                transition: 'background-color 0.25s ease',
            }}
        >
            <Box sx={{ flex: 0.5 }} display={'flex'}>
                <DragIndicator fontSize="large" />
            </Box>
            <Box sx={{ flex: 4.5 }}>{item.common.identifier}</Box>
            <Box sx={{ flex: 3 }}>{item.common.name}</Box>
            <Box sx={{ flex: 3 }}>{t(`common.types.${item.__typename?.replace('Attribute', '')}`)}</Box>
            <Box display={'flex'} alignItems={'center'}>
                {category === DataTypeCategory.DATA_MODULE && (
                    <Tooltip
                        title={
                            item.common.visible
                                ? t('drawerPanels.dataTypeEditPanel.hide')
                                : t('drawerPanels.dataTypeEditPanel.show')
                        }
                        placement="left"
                    >
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation()
                                onClickToggleVisibilityAttribute(item)
                            }}
                        >
                            {item.common.visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title={t('drawerPanels.dataTypeEditPanel.delete')} placement="left">
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation()
                            onClickDeleteAttribute(item)
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}
