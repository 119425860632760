import { Box, Typography } from '@mui/material'

export interface HeadlineProps {
    text: string
}

export const Headline = (props: HeadlineProps): JSX.Element => {
    const { text } = props
    return (
        <Box
            sx={{
                width: '100%',
                mt: 3,
                mb: 1,
                borderBottom: '1px solid #999',
            }}
        >
            <Typography variant="h5">{text}</Typography>
        </Box>
    )
}
