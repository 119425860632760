import { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material'
import { useState } from 'react'

type AutocompletePropsAlias<T> = AutocompleteProps<AutocompleteNestedItem<T>, false, false, false>
export type AutocompleteNestedProps<T> = Pick<
    AutocompletePropsAlias<T>,
    | 'options'
    | 'getOptionLabel'
    | 'renderInput'
    | 'renderGroup'
    | 'renderOption'
    | 'fullWidth'
    | 'onChange'
    | 'noOptionsText'
    | 'defaultValue'
    | 'disableCloseOnSelect'
> & {
    isFinalItem?: (item: T | undefined) => boolean // decides whether the drawer closes when a specific item is clicked
}

export interface AutocompleteNestedItem<T> {
    item: T
    group: string // nested group identifier, with individual nesting depths represented by their keys separated by /, ending on /
    key: string // unique key in group
    value: string // string representation of key
}

const getGlobalKey = (option: AutocompleteNestedItem<unknown>) => option.group + option.key + '/'

export const useAutocompleteNested = <T>(props: AutocompleteNestedProps<T>): AutocompletePropsAlias<T> => {
    const {
        options,
        getOptionLabel,
        renderInput,
        renderGroup,
        renderOption,
        fullWidth,
        onChange,
        noOptionsText,
        defaultValue,
        disableCloseOnSelect,
        isFinalItem,
    } = props

    const [inputValue, setInputValue] = useState('')
    const [value, setValue] = useState<AutocompleteNestedItem<T> | null>(defaultValue ?? null)
    const [inputChangeReason, setInputChangeReason] = useState<AutocompleteInputChangeReason>('clear')
    const [open, setOpen] = useState(false)

    // sort passed options by the nesting depth and alphanumeric value
    const sortedOptions = [...options].sort((a, b) => {
        const difference = a.group.split('/').length - b.group.split('/').length

        // https://stackoverflow.com/questions/2140627/how-to-do-case-insensitive-string-comparison
        if (difference === 0) return a.value.localeCompare(b.value, undefined, { sensitivity: 'base' })
        else return difference
    })

    // TODO: Some kind of quick exit would be nice, but didnt find a way
    // tried with onkeydown, onchange and onselect, but none catch doubleclick. so we just have to esc for now
    // basic autocomplete props with automatic types
    return {
        // pass down props
        renderInput,
        renderGroup,
        renderOption,
        fullWidth,
        noOptionsText,
        disableCloseOnSelect,
        options: sortedOptions,
        getOptionLabel: getOptionLabel ?? getGlobalKey,
        groupBy: (option) => option.group,
        isOptionEqualToValue: (option, value) => getGlobalKey(option) === getGlobalKey(value),
        filterOptions: (options) => {
            const search = inputValue.toLocaleLowerCase()
            return options.filter((option) => {
                // something manually entered
                if (inputChangeReason === 'input') {
                    // filter option by key
                    const inKey = getGlobalKey(option).toLocaleLowerCase().includes(search)
                    // by value
                    const inValue = option.value.toLocaleLowerCase().includes(search)
                    return inKey || inValue
                }

                // nothing selected
                // nothing manually entered
                if (value === null) return true

                // show items that are parents, siblings or children of the selected option
                // TODO: does this feel good to use?
                // and if not, how else do we implement navigating down the stack?
                const isParent = getGlobalKey(option) === value.group
                const isSibling = option.group === value.group
                const isChild = getGlobalKey(option).startsWith(getGlobalKey(value))
                return isParent || isSibling || isChild
            })
        },
        inputValue,
        onInputChange: (_, value, reason) => {
            setInputValue(value)
            setInputChangeReason(reason)
        },
        value,
        onChange: (event, newValue, reason, details) => {
            setValue(newValue)

            // pass down event
            if (onChange) onChange(event, newValue, reason, details)
            // check if the selected item is final, if so close the drawer
            if (isFinalItem && isFinalItem(newValue?.item)) setOpen(false)
        },
        open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
    }
}
