import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles'
import colors from 'utils/colors'
//import colors from './colors'
//import fontStyles from './fontStyles'

// const breakpoints = createBreakpoints({})
// export const smallMobile = 385
// export const smallestMobile = 360

const theme: Theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '&.Normal': {
                        backgroundColor: colors.buttons.normal,
                        '&:hover, &.Active': {
                            backgroundColor: colors.buttons.normalHover,
                        },
                    },
                    '&.Beige': {
                        backgroundColor: colors.buttons.beige,
                        '&:hover, &.Active': {
                            backgroundColor: colors.buttons.beigeHover,
                        },
                    },
                    '&.White': {
                        backgroundColor: colors.buttons.white,
                        '&:hover, &.Active': {
                            backgroundColor: colors.buttons.whiteHover,
                        },
                    },
                    '&.Disabled': {
                        backgroundColor: colors.buttons.disabled,
                    },
                    '&.Normal, &.Beige, &.White': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 0,
                        transition: 'background-color 250ms',
                        '& .MuiTypography-root': {
                            color: colors.black,
                            fontWeight: 600,
                            letterSpacing: '0.1rem',
                            textTransform: 'uppercase',
                        },
                    },
                    '&.SizeNormal': {
                        height: '50px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    },
                    '&.TextNormal': {
                        '& .MuiTypography-root': {
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            textAlign: 'center',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-all',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#5eafc7',
        },
        secondary: {
            main: '#cccccc',
        },
    },
})

export const ModifiedTheme = responsiveFontSizes(theme)
