import { ApolloCache, DefaultContext, MutationTuple } from '@apollo/client'
import {
    AttributeAsset,
    AttributeAssetInput,
    AttributeAssetValue,
    AttributeBoolean,
    AttributeBooleanInput,
    AttributeBooleanValue,
    AttributeCollection,
    AttributeCollectionInput,
    AttributeCollectionValue,
    AttributeDate,
    AttributeDateInput,
    AttributeDateValue,
    AttributeLink,
    AttributeLinkInput,
    AttributeLinkValue,
    AttributeNumber,
    AttributeNumberInput,
    AttributeNumberValue,
    AttributeSelection,
    AttributeSelectionInput,
    AttributeSelectionValue,
    AttributeText,
    AttributeTextInput,
    AttributeTextValue,
    DataItem,
    DataItemChange,
    DataItemChangeType,
    DataItemInput,
    DataType,
    DataTypeAttributeType,
    DataTypeCategory,
    DataTypeChangeType,
    DataTypeInput,
    Mutation,
    MutationDataTypeCreateAttributeTextArgs,
} from 'graphql/types'
import _ from 'lodash'
import { LinkData } from 'utils/types'

export type AttributeUnion =
    | AttributeText
    | AttributeNumber
    | AttributeBoolean
    | AttributeAsset
    | AttributeLink
    | AttributeSelection
    | AttributeCollection
    | AttributeDate

export type AttributeValueUnion =
    | AttributeTextValue
    | AttributeNumberValue
    | AttributeBooleanValue
    | AttributeAssetValue
    | AttributeLinkValue
    | AttributeDateValue
    | AttributeSelectionValue
    | AttributeCollectionValue

export interface DataItemUnion {
    id: string
    published: boolean
    created: DataItemChange
    updated: DataItemChange
    values: AttributeValueUnion[]
}

export type AttributeInputUnion =
    | (AttributeTextInput & { _type: DataTypeAttributeType.TEXT })
    | (AttributeNumberInput & { _type: DataTypeAttributeType.NUMBER })
    | (AttributeBooleanInput & { _type: DataTypeAttributeType.BOOLEAN })
    | (AttributeAssetInput & { _type: DataTypeAttributeType.ASSET })
    | (AttributeLinkInput & { _type: DataTypeAttributeType.LINK })
    | (AttributeDateInput & { _type: DataTypeAttributeType.DATE })
    | (AttributeSelectionInput & { _type: DataTypeAttributeType.SELECTION })
    | (AttributeCollectionInput & { _type: DataTypeAttributeType.COLLECTION })

export type AttributeTypename =
    | 'AttributeText'
    | 'AttributeNumber'
    | 'AttributeBoolean'
    | 'AttributeAsset'
    | 'AttributeLink'
    | 'AttributeSelection'
    | 'AttributeCollection'
    | 'AttributeDate'

export type MutationAttributeInputUnion = Omit<AttributeInputUnion, '_type'>
export type DataTypeCreateAttributeArgs = Omit<MutationDataTypeCreateAttributeTextArgs, 'input'> & {
    input: MutationAttributeInputUnion
}
export type DataTypeUpdateAttributeArgs = { input: MutationAttributeInputUnion }
export interface DataTypeCreateAttributeHook {
    type: DataTypeAttributeType
    mutation: MutationTuple<Mutation, DataTypeCreateAttributeArgs, DefaultContext, ApolloCache<any>>
}
export interface DataTypeUpdateAttributeHook {
    type: DataTypeAttributeType
    mutation: MutationTuple<Mutation, DataTypeUpdateAttributeArgs, DefaultContext, ApolloCache<any>>
}

export const createDefaultDataType = (category: DataTypeCategory): DataType => ({
    id: '',
    category,
    identifier: '',
    description: '',
    name: '',
    attributeAssetList: [],
    attributeBooleanList: [],
    attributeCollectionList: [],
    attributeLinkList: [],
    attributeDateList: [],
    attributeNumberList: [],
    attributeSelectionList: [],
    attributeTextList: [],
    created: {
        timestamp: Date.now() / 1000,
        user: { id: '', name: '' },
        changeType: DataTypeChangeType.CREATE,
    },
    updated: {
        timestamp: Date.now() / 1000,
        user: { id: '', name: '' },
        changeType: DataTypeChangeType.UPDATE,
    },
})

export const createDefaultDataItem = (attributes: AttributeUnion[]): DataItemUnion => {
    const change: DataItemChange = {
        timestamp: Date.now() / 1000,
        user: { id: '', name: '' },
        changeType: DataItemChangeType.CREATE,
    }
    return {
        id: '',
        published: false,
        created: change,
        updated: change,
        values: createInitialAttributeValues(attributes),
    }
}

export const getDataTypeInputFromType = (item: DataType): DataTypeInput => ({
    id: item.id,
    category: item.category,
    identifier: item.identifier,
    description: item.description,
    name: item.name,
})

export const getDataItemInputFromType = (item: DataItem): DataItemInput =>
    _.omit(item, '__typename', 'created', 'updated')

export enum LinkOption {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    FILE = 'FILE',
}
export const getLinkOptionFromValue = (value: string): LinkOption => {
    if (value.startsWith('Page/')) return LinkOption.INTERNAL
    else if (value.startsWith('Asset/')) return LinkOption.FILE
    else if (value.length > 0) return LinkOption.EXTERNAL
    return LinkOption.INTERNAL
}

export const getLinkOptionFromLinkData = (value: LinkData): LinkOption => {
    if (value.__qbd_fileId) return LinkOption.FILE
    else if (value.__qbd_pageId) return LinkOption.INTERNAL
    else if (value.url) return LinkOption.EXTERNAL
    return LinkOption.INTERNAL
}

// should a link field show an option?
// Internal = internal page links by id presumably
// External = any URL
// File = file download from cms assets
export const shouldLinkShowOption = (link: AttributeLink, option: LinkOption) => link.allowed.includes(option)

export const createInitialAttributeValues = (attributes: AttributeUnion[]): AttributeValueUnion[] =>
    attributes.map((item) => {
        let value: AttributeValueUnion
        switch (item.__typename) {
            case 'AttributeAsset':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeAssetValue',
                    value: '',
                }
                break
            case 'AttributeBoolean':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeBooleanValue',
                    value: item.defaultValue ?? false,
                }
                break
            case 'AttributeCollection':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeCollectionValue',
                    value: [],
                }
                break
            case 'AttributeLink':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeLinkValue',
                    value: '',
                    newWindow: false,
                }
                break
            case 'AttributeNumber':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeNumberValue',
                    value: item.defaultValue ?? 0,
                }
                break
            case 'AttributeSelection':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeSelectionValue',
                    value: item.defaultValue ?? '',
                }
                break
            case 'AttributeText':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeTextValue',
                    value: item.defaultValue ?? '',
                }
                break
            case 'AttributeDate':
                value = {
                    attributeID: item.id,
                    __typename: 'AttributeDateValue',
                    value: Math.round(Date.now() / 1000),
                }
                break
            default:
                throw Error('invalid attribute type when creating new item')
        }
        return value
    })
